import React from 'react';
import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import { RoutesForNavigationDrawerWithVerticalMenu } from 'types/interfaces';

type Props = {
  routes: RoutesForNavigationDrawerWithVerticalMenu[];
  children: any;
  hideLocations?: boolean;
  hideZone?: boolean;
  hideSubZone?: boolean;
  hideCluster?: boolean;
  hideLocation?: boolean;
  showSearchbar?: boolean;
  unsetOverflowStyle?: boolean;
  customStyle?: any;
};

export default function AnalyticsWrapper({
  routes, children, hideLocations, hideZone = false, hideSubZone = false, hideCluster = false, hideLocation = false, showSearchbar, unsetOverflowStyle, customStyle = {},
}: Props) {
  return (
    <>
    <Header
      hideFilters={hideLocations}
      hideZone={hideZone}
      hideSubZone={hideSubZone}
      hideCluster={hideCluster}
      hideLocation={hideLocation}
      showSearchbar={showSearchbar}
    />
      <NavigationDrawerWithSideMenu
        routes={routes}
        openDrawer
      />
      <div style={{ marginLeft: 220, paddingTop: 30, maxHeight: 'calc(100vh - 60px)', ...!unsetOverflowStyle && ({ overflowY: 'scroll' }), ...customStyle }}>
        { children }
      </div>
    </>
  );
}
