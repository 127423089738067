import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Box, FormControl, Select } from '@mui/material';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';

import { useUpdateUserInfoMutation, useUpdateUserAccessLocationMutation } from 'generated/graphql';
import ImageLinks from 'utils/ImageLinks';
import ProfileStatus from 'assets/icons/um-profile-status.svg';
import request from 'utils/request';
import { toast, ToastOptions } from 'react-toastify';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { DOCTOR_STATUS, LOCATION_TYPES, ROLES_CATEGORY, TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'store/selectors/user.selectors';
import { UserManagementContext } from '../../context';
import { canUserDeleteUsers } from '../../../../../../utils/shared/canUserAccessFeature';

const Profile = (props: any) => {
  const { t } = useTranslation();
  const {
    isEditing,
    isLoading,
    setIsEditing,
    setIsLoading,
    originalUser,
    editingUser,
    accessLocationsIds,
    clearContext,
    user,
    startWorkingDay,
    setStartWorkingDay,
  } = useContext(UserManagementContext);
  const editingUserGeneralInfo = editingUser?.get('general')?.toJS();
  const editingUserVisitorInfo = editingUser?.get('info')?.toJS();
  const [setUpdateUserInfo] = useUpdateUserInfoMutation();
  const [setUpdateUserAccessLocation] = useUpdateUserAccessLocationMutation();
  const profile = originalUser?.get('profile')?.toJS();
  const [status, setStatus] = useState('');
  const loggedInUser = useSelector(selectUser);
  const [showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal] = useState<boolean>(
    false,
  );
  const [deleteUserConfirmationModal, setDeleteUserConfirmationModal] = useState({
    isLoading: false,
    description: 'Do you want to delete this user?',
    okButtonText: t(TRANSLATIONS.DELETE),
  });
  const [isRoleMissingWarning, setIsRoleMissingWarning] = useState<boolean>(
    false,
  );
  const [isRoleLocationWarning, setIsRoleLocationWarning] = useState<boolean>(false);

  useEffect(() => {
    if (!isEditing) {
      setIsRoleLocationWarning(false);
    }
  }, [isEditing]);

  useEffect(() => {
    if (!user?.userRole) {
      setIsRoleMissingWarning(true);
    }
  }, [user]);

  useEffect(() => {
    if (isEditing) {
      if (isRoleMissingWarning) {
        toast.warning(t(TRANSLATIONS.EDIT_USER_WITHOUT_ROLE));
      }
      if (isRoleLocationWarning) {
        toast.warning(t(TRANSLATIONS.ROLE_AND_LOCATION_TYPE_MISMATCH));
      }
    }
  }, [isRoleMissingWarning, isRoleLocationWarning, isEditing, t]);

  useEffect(() => {
    const selectedUserRoleCategory = editingUser.getIn(['permissions', 'roleCategory']);
    const selectedUserOperationType = editingUser.getIn(['general', 'distributionCenter', 'type']);

    if (isEditing) {
      if (selectedUserRoleCategory) {
        if (
          ((selectedUserRoleCategory === ROLES_CATEGORY.LOGISTICS)
            && (selectedUserOperationType !== LOCATION_TYPES.DISTRIBUTION_CENTER))
            || ((selectedUserRoleCategory === ROLES_CATEGORY.SUPPLY)
            && (selectedUserOperationType !== LOCATION_TYPES.FACTORY))
        ) {
          setIsRoleLocationWarning(true);
        } else {
          setIsRoleLocationWarning(false);
        }
      }
    }
  }, [editingUser, isEditing, isRoleLocationWarning]);

  const updatedByUserId = loggedInUser.get('id');
  const { getUserInfo, isAuthorisedForOWD, showDeletedUsers, isReactiveUsers, autoAssignSios } = props;

  useEffect(() => {
    if (isEditing && !startWorkingDay && !showDeletedUsers) {
      setStartWorkingDay(editingUser.getIn(['general', 'startWorkingDay']));
    }
  }, [isEditing, startWorkingDay, setStartWorkingDay, editingUser, showDeletedUsers]);

  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const canDeleteUsers = canUserDeleteUsers(loggedInUser?.get('features'));

  function toggleDeleteConfirmationModel() {
    setShowDeleteUserConfirmationModal(prevState => !prevState);
  }

  const handleDeleteUser = async () => {
    try {
      setDeleteUserConfirmationModal(prevState => ({ ...prevState, isLoading: true }));
      await request.delete(`/api/v1/users/${profile?.slug}`);
      toast.success('User deleted successfully', toastConfig);
      clearContext();
      toggleDeleteConfirmationModel();
      await getUserInfo();
    } catch (e) {
      if (e.message === 'Forbidden') {
        toast.error(t(TRANSLATIONS.FORBIDDEN_MISSING_PERMISSIONS), toastConfig);
      }
      setDeleteUserConfirmationModal(prevState => ({
        ...prevState,
        description: 'Failed to delete user',
        okButtonText: 'Retry',
        isLoading: false,
      }));
    }
  };

  const handleUpdateUser = async () => {
    const isEditedLocationDC = editingUserGeneralInfo?.distributionCenter?.type === 'DISTRIBUTION_CENTER';
    if (autoAssignSios) {
      if (!editingUserGeneralInfo?.userWorkLocation?.workLocation) {
        return toast.error(t(TRANSLATIONS.SELECT_LOCATIONS_FOR_AUTO_ASSIGN_SIOS_IN_PERMISSION_TAB), toastConfig);
      }
      if (!editingUserGeneralInfo?.userWorkLocation?.subLocation) {
        return toast.error(t(TRANSLATIONS.SELECT_LOCATIONS_FOR_AUTO_ASSIGN_SIOS_IN_PERMISSION_TAB), toastConfig);
      }
    }
    if (showDeletedUsers) {
      let filledFieldsCount = 0;
      if (editingUserGeneralInfo?.position) filledFieldsCount += 1;
      if (editingUserGeneralInfo?.distributionCenter?.id) filledFieldsCount += 1;
      if (startWorkingDay) filledFieldsCount += 1;
      if (filledFieldsCount < 2) {
        return toast.error(t(TRANSLATIONS.MISSING_DATA_TO_FILL), toastConfig);
      }
    }
    if (showDeletedUsers && !editingUserGeneralInfo?.distributionCenter?.id) {
      return toast.error(t(TRANSLATIONS.WORKS_AT_FIELD_NEEDS_TO_BE_ADDED_TO_USER), toastConfig);
    }
    if (!editingUserGeneralInfo?.position) {
      if (showDeletedUsers) {
        return toast.error(t(TRANSLATIONS.USER_NEEDS_TO_BE_ASSIGNED_A_POSITION), toastConfig);
      }
      return toast.error('There was an error. The user needs a position to be assigned. You can assign it on the "General" tab.', toastConfig);
    }
    if (showDeletedUsers && !startWorkingDay) {
      return toast.error(t(TRANSLATIONS.USERS_HIRING_DATE_NEEDS_TO_BE_ADDED), toastConfig);
    }
    const originalRoleCategoryIsLogistics = user?.userRole?.category === ROLES_CATEGORY.LOGISTICS;
    const editingUserProfileInfo = editingUser?.get('profile')?.toJS();
    const editingUserPermissionsInfo = editingUser?.get('permissions')?.toJS();
    const editRoleIsLogistics = editingUserPermissionsInfo?.roleCategory
    === ROLES_CATEGORY.LOGISTICS;

    if (!editingUserPermissionsInfo.roleId && !editingUserPermissionsInfo.roleSlug) {
      editingUserPermissionsInfo.roleId = 31;
      editingUserPermissionsInfo.roleSlug = 'certificates_only';
    }
    try {
      setIsLoading(true);
      if (editingUserProfileInfo?.id) {
        await setUpdateUserAccessLocation({
          variables: {
            locationIds: accessLocationsIds,
            userId: editingUserProfileInfo?.id,
          },
        });
      }
      const routines: any = [];
      if (editRoleIsLogistics
              || (!editingUserPermissionsInfo?.roleCategory
                && originalRoleCategoryIsLogistics)) {
        if (editingUserPermissionsInfo?.routines) {
          Object.values(editingUserPermissionsInfo?.routines).forEach((item: any) => {
            if (item?.routines && item?.routines?.length) {
              item?.routines?.map((routine: any) => {
                routines.push(routine);
              });
            }
          });
        }
      }

      await setUpdateUserInfo({
        variables: {
          id: editingUserProfileInfo?.id,
          updatedByUserId,
          general: {
            locationId: editingUserGeneralInfo?.distributionCenter?.id,
            position: editingUserGeneralInfo?.position || '',
            userWorkLocation: editingUserGeneralInfo.userWorkLocation,
            startWorkingDay,
          },
          permission: {
            routines: routines.length ? routines : null,
            type: editingUserPermissionsInfo?.type?.type,
            roleSlug: editingUserPermissionsInfo?.roleSlug,
            autoAssignSios: autoAssignSios,
          },
          roleId: editingUserPermissionsInfo?.roleId,
          userInfo: {
            isAuthorisedForOWD: (editRoleIsLogistics
              || (!editingUserPermissionsInfo?.roleCategory
                && originalRoleCategoryIsLogistics)) ? isAuthorisedForOWD : null,
            visitorType: editingUserVisitorInfo?.visitorType,
          },
        },
      });

      await toast.success(t(TRANSLATIONS.USER_UPDATED_SUCCESSFULLY));
      setIsEditing(false);
      clearContext();
      await getUserInfo();
    } catch (e) {
      if (!editingUserGeneralInfo?.position && isEditedLocationDC) {
        toast.error('There was an error. The user needs a position to be assigned. You can assign it on the "General" tab.', toastConfig);
      } else if (!editingUserGeneralInfo?.distributionCenter?.id) {
        toast.error('There was an error. The user needs a location to be assigned. You can assign it on the "General" tab.', toastConfig);
      } else {
        toast.error(`An Error Occured. ${e}`, toastConfig);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserStatus = async (slug: string, userStatus: string) => {
    const url = `/api/v1/users/${slug}/${userStatus}`;
    try {
      setIsLoading(true);
      if (userStatus === 'send-to-doctor') {
        await request.post(url, {});
      } else {
        await request.patch(url);
      }

      toast.success('User status updated successfully', toastConfig);
      clearContext();
      await getUserInfo();
    } catch (e) {
      toast.error(`User Status Updatation Failed ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = (e: any) => {
    setStatus(e.target?.value);
    handleUserStatus(profile?.slug, e.target.value);
  };

  const toCamelCase = (str: string) => (str
    ? str
      .toLowerCase()
      .replace(/(^\w|\s\w)(\S*)/g, (_ignore, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
    : '');

  return (
    <Box>
      {showDeleteUserConfirmationModal && (
        <ConfirmationModal
          title={`${t(TRANSLATIONS.DELETE)} User`}
          description={deleteUserConfirmationModal.description}
          isOpen={showDeleteUserConfirmationModal}
          onOk={() => handleDeleteUser()}
          okButtonText={deleteUserConfirmationModal.okButtonText}
          okButtonIcon={ImageLinks.deleteIcon}
          cancelButtonText={t(TRANSLATIONS.CANCEL)}
          cancelButtonIcon={ImageLinks.leftArrowIcon}
          onCancel={toggleDeleteConfirmationModel}
          isOkButtonLoading={deleteUserConfirmationModal.isLoading}
          cancelButtonStyle={{ width: '110px' }}
          okButtonStyle={{ color: '#C00000', width: '106px' }}
        />
      )}
      <div className="profile-container">
        <div className="d-flex align-items-center">
          {profile?.picture ? (
            <Avatar className="profile-avatar" alt={profile?.name} src={profile?.picture} />
          ) : (
            <Avatar className="profile-avatar">
              {profile?.name
                ?.match(/(^\S\S?|\b\S)?/g)
                .join('')
                .match(/(^\S|\S$)?/g)
                .join('')
                .toUpperCase()}
            </Avatar>
          )}
          <div className="pl-2">
            <h3 className="profile-name">{profile?.name}</h3>
            <h5 className="profile-position">{toCamelCase(profile?.position)} </h5>
            <span className="profile-user-type">
              {profile?.employeeNumber ? 'Interno' : 'Externo'}
            </span>
          </div>
        </div>
        <div style={{ minWidth: '178px' }}>
          {showDeletedUsers ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {isEditing && (
              <Button
                variant="outlined"
                size="small"
                disabled={isLoading}
                style={{ color: '#c00000' }}
                onClick={isEditing
                  ? () => setIsEditing((s: boolean) => !s)
                  : () => toggleDeleteConfirmationModel()}
              >
                {t(TRANSLATIONS.CANCEL)}
              </Button>
              )}
              <Button
                style={{ color: `${isEditing ? 'white' : '#2b323b'}`, minWidth: '179px' }}
                className="ml-2"
                color="primary"
                size="small"
                disabled={isLoading || (!props.hasManagmentAccess && !isReactiveUsers())}
                variant={isEditing ? 'contained' : 'outlined'}
                onClick={
              isEditing
                ? () => handleUpdateUser()
                : () => {
                  setIsEditing((s: boolean) => !s);
                  setIsLoading(true);
                }
            }
                startIcon={isEditing ? '' : <EditOutlined style={{ color: 'black' }} />}
              >
                {t(TRANSLATIONS.REHABILITATE_USER)}
              </Button>
            </div>
          ) : (
            <>
              {(props.hasManagmentAccess || canDeleteUsers)
                ? (
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={isLoading}
                    style={{ color: '#c00000' }}
                    onClick={
                isEditing
                  ? () => setIsEditing((s: boolean) => !s)
                  : () => toggleDeleteConfirmationModel()
              }
                  >
                    {isEditing ? t(TRANSLATIONS.CANCEL) : <DeleteOutlineOutlined style={{ color: 'red' }} />}
                  </Button>
                ) : null}
              <Button
                style={{ color: `${isEditing ? 'white' : '#2b323b'}` }}
                className="ml-2"
                color="primary"
                size="small"
                disabled={isLoading || !props.hasManagmentAccess}
                variant={isEditing ? 'contained' : 'outlined'}
                onClick={
              isEditing
                ? () => handleUpdateUser()
                : () => {
                  setIsEditing((s: boolean) => !s);
                  setIsLoading(true);
                }
            }
                startIcon={isEditing ? '' : <EditOutlined style={{ color: 'black' }} />}
              >
                {isEditing ? t(TRANSLATIONS.SAVE) : t(TRANSLATIONS.EDIT)}
              </Button>
            </>
          )}
        </div>
      </div>
      {isEditing ? (
        <div className="profile-status-container" style={{ border: '2px dotted #808080' }}>
          <div className="d-flex align-items-center py-1 pl-1">
            <img width="24px" src={ImageLinks.edit} alt="profile-status" />
            <span className="pl-2">Editando, al terminar da click en ”Guardar”.</span>
          </div>
        </div>
      ) : (
        <div className="profile-status-container">
          <div className="d-flex align-items-center">
            <img src={ProfileStatus} alt="profile-status" />
            <span className="pl-2">
              Activo desde el{' '}
              {profile?.createdAt ? moment(profile?.createdAt).format('Do MMMM YYYY') : ''}
            </span>
          </div>
          {profile?.doctorStatus === DOCTOR_STATUS.IN_TEST ? (
            <p className="greyColored">En pruebas...</p>
          ) : profile?.doctorStatus === DOCTOR_STATUS.QUARANTINE ? (
            <p className="greyColored">En cuarentena</p>
          ) : (
            <FormControl variant="outlined">
              <Select
                native
                className="select-status"
                value={status}
                disabled={isLoading}
                onChange={handleStatusChange}
                label={t(TRANSLATIONS.CHOOSE)}
              >
                <option value="" disabled>
                  {t(TRANSLATIONS.CHOOSE)}
                </option>
                {profile?.isBlocked ? (
                  <>
                    <option value="unblock">Unblock</option>
                    <option value="send-to-doctor">Medical Examination</option>
                  </>
                ) : (
                  <option value="block">Block</option>
                )}
              </Select>
            </FormControl>
          )}
        </div>
      )}
    </Box>
  );
};

function mapStateToProps(state: any) {
  return {
    hasManagmentAccess: state.getIn(['user', 'hasUserManagementAccess']),
  };
}

export default connect(mapStateToProps)(Profile);
