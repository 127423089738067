import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  TRANSLATIONS,
  BLOCKED_REASONS,
  TICKET_TYPE,
  SECURITY_STATUS,
  RISK_LEVEL,
  PRIORITY,
} from './types/enums';

export const pluralize = (str: string) => `${str}_plural`;

const resources = {
  es: {
    translation: {
      [TRANSLATIONS.APPLY_OWDS]: 'Aplicar OWDs',
      [TRANSLATIONS.SELECT_DISTRIBUTION_PARAGRAPH]: 'Para poder acceder a la configuración de las OWDs es necesario seleccionar un de Distribución utilizando la barra superior',
      [TRANSLATIONS.SELECT_DISTRIBUTION_5S_PARAGRAPH]: 'Para poder acceder a la configuración de 5s es necesario seleccionar un Centro de distribución utilizando la barra superior.',
      [TRANSLATIONS.LOADING_FILE]: 'Cargando archivo',
      [TRANSLATIONS.LAST_LOAD]: 'Ultima carga',
      [TRANSLATIONS.TEMPLATE]: 'Plantilla',
      [TRANSLATIONS.OVER_WRITE_FILE]: 'Sobreescribir archivo',
      [TRANSLATIONS.DOWNLOAD_FILE]: 'Descargar archivo',
      [TRANSLATIONS.DOWNLOAD_TEMPLATE]: 'Descargar Plantilla',
      [TRANSLATIONS.ARCHIVO]: 'Archivo',
      [TRANSLATIONS.WITHIN_24_HOURS]: 'Dentro de 24 horas',
      [TRANSLATIONS.MORE_24_HOURS]: 'Fuera de 24 horas',
      [TRANSLATIONS.week1]: '1er sem',
      [TRANSLATIONS.week2]: '2da sem',
      [TRANSLATIONS.week3]: '3ra sem',
      [TRANSLATIONS.week4]: '4ta sem',
      [TRANSLATIONS.week5]: '5ta sem',
      [TRANSLATIONS.week6]: '6ta sem',
      [TRANSLATIONS.week7]: '7ma sem',
      [TRANSLATIONS.week8]: '8ma sem',
      [TRANSLATIONS.week9]: '9ma sem',
      [TRANSLATIONS.week10]: '10ma sem',
      [TRANSLATIONS.week11]: 'sem 11',
      [TRANSLATIONS.week12]: 'sem 12',
      [TRANSLATIONS.week13]: 'sem 13',
      [TRANSLATIONS.week14]: 'sem 14',
      [TRANSLATIONS.week15]: 'sem 15',
      [TRANSLATIONS.week16]: 'sem 16',
      [TRANSLATIONS.week17]: 'sem 17',
      [TRANSLATIONS.week18]: 'sem 18',
      [TRANSLATIONS.week19]: 'sem 19',
      [TRANSLATIONS.week20]: 'sem 20',
      [TRANSLATIONS.week21]: 'sem 21',
      [TRANSLATIONS.week22]: 'sem 22',
      [TRANSLATIONS.week23]: 'sem 23',
      [TRANSLATIONS.week24]: 'sem 24',
      [TRANSLATIONS.week25]: 'sem 25',
      [TRANSLATIONS.week26]: 'sem 26',
      [TRANSLATIONS.week27]: 'sem 27',
      [TRANSLATIONS.week28]: 'sem 28',
      [TRANSLATIONS.week29]: 'sem 29',
      [TRANSLATIONS.week30]: 'sem 30',
      [TRANSLATIONS.week31]: 'sem 31',
      [TRANSLATIONS.week32]: 'sem 32',
      [TRANSLATIONS.week33]: 'sem 33',
      [TRANSLATIONS.week34]: 'sem 34',
      [TRANSLATIONS.week35]: 'sem 35',
      [TRANSLATIONS.week36]: 'sem 36',
      [TRANSLATIONS.week37]: 'sem 37',
      [TRANSLATIONS.week38]: 'sem 38',
      [TRANSLATIONS.week39]: 'sem 39',
      [TRANSLATIONS.week40]: 'sem 40',
      [TRANSLATIONS.week41]: 'sem 41',
      [TRANSLATIONS.week42]: 'sem 42',
      [TRANSLATIONS.week43]: 'sem 43',
      [TRANSLATIONS.week44]: 'sem 44',
      [TRANSLATIONS.week45]: 'sem 45',
      [TRANSLATIONS.week46]: 'sem 46',
      [TRANSLATIONS.week47]: 'sem 47',
      [TRANSLATIONS.week48]: 'sem 48',
      [TRANSLATIONS.week49]: 'sem 49',
      [TRANSLATIONS.week50]: 'sem 50',
      [TRANSLATIONS.week51]: 'sem 51',
      [TRANSLATIONS.week52]: 'sem 52',
      [TRANSLATIONS.week53]: 'sem 53',
      [TRANSLATIONS.week54]: 'sem 54',
      [TRANSLATIONS.REFERNCIA]: 'Referncia',
      [TRANSLATIONS.DATE_INCIDENT]: 'Fecha incidente',
      [TRANSLATIONS.VALIDITY]: 'Vigencia',
      [TRANSLATIONS.NATURE]: 'Naturaleza',
      [TRANSLATIONS.CAUSE]: 'Causa',
      [TRANSLATIONS.SUB_CAUSE]: 'Causa raiz',
      [TRANSLATIONS.CREATE_YOUR_PASSWORD_TO_ACCESS]: 'Crea tu contraseña para acceder a la plataforma',
      [TRANSLATIONS.PASSWORD]: 'Contraseña',
      [TRANSLATIONS.CONFIRM_PASSWORD]: 'Confirmar contraseña',
      [TRANSLATIONS.FORGOT_YOUR_PASSWORD]: '¿Se te olvidó tu contraseña?',
      [TRANSLATIONS.I_REMEMBER_MY_PASSWORD]: 'Recuerdo mi contraseña',
      [TRANSLATIONS.RECOVER_IT_HERE]: 'Recupérala aquí.',
      [TRANSLATIONS.LETS_FIND_YOUR_ACC]: 'Busquemos tu cuenta ',
      [TRANSLATIONS.TYPE_YOUR_EMAIL]: 'Escribe el correo electrónico asociado a tu cuenta.',
      [TRANSLATIONS.SEND_ME_THE_LINK]: 'ENVIAR CORREO',
      [TRANSLATIONS.EMAIL_SENT]: 'Listo! Correo enviado',
      [TRANSLATIONS.YOU_WILL_RECEIVE_AN_EMAIL]: 'Te llegará un correo a',
      [TRANSLATIONS.TO_RESET_PASSWORD]: 'para que reestablezcas tu contraseña.',
      [TRANSLATIONS.ALREADY_HAVE_AN_ACCOUNT]: 'Ya tienes cuenta?',
      [TRANSLATIONS.LOGIN]: 'Inicia sesión',
      [TRANSLATIONS.GO_TO_LOGIN]: 'Ir a inicio de sesión',
      [TRANSLATIONS.EMAIL]: 'Correo',
      [TRANSLATIONS.LOADING]: 'Cargando',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED]: 'Tú contraseña fue creada exitosamente.',
      [TRANSLATIONS.YOU_CAN_START_USING_THE_APP]: '¡Ya puedes empezar a utilizar la app!',
      [TRANSLATIONS.OPEN_APP]: 'Abrir app',
      [TRANSLATIONS.CONTACT]: 'Contacto',
      [TRANSLATIONS.THIS_URL_HAS_EXPIRED]: 'Ya expiró esta liga',
      [TRANSLATIONS.LOGIN_TO_ACCESS_SAFETY_DASHBOARD]: 'Iniciar sesión para entrar al dashboard de Safety 360',
      [TRANSLATIONS.LOGGING_IN]: 'Iniciando Sesión',
      [TRANSLATIONS.NAME]: 'Nombre',
      [TRANSLATIONS.FIRST_SURNAME]: 'Primer apellido',
      [TRANSLATIONS.SECOND_SURNAME]: 'Segundo apellido',
      [TRANSLATIONS.OPTIONAL]: 'Opcional',
      [TRANSLATIONS.BLOCKED_REASON]: 'Motivo de bloqueo',
      [TRANSLATIONS.SEARCH]: 'Buscar',
      [TRANSLATIONS.BLOCK]: 'Bloquear',
      [TRANSLATIONS.UNBLOCK]: 'Desbloquear',
      [TRANSLATIONS.CD_SETTINGS]: 'Configuración {{ location }}',
      [TRANSLATIONS.BACK_TO]: 'Volver a',
      [TRANSLATIONS.SETTINGS]: 'Configuración',
      [TRANSLATIONS.BLOCK_UNBLOCK]: 'Bloquear/Desbloquear',
      [TRANSLATIONS.EXIT]: 'Salir',
      [TRANSLATIONS.FILE_UPLOAD]: 'Carga de archivos',
      [TRANSLATIONS.WELCOME]: 'Bienvenido',
      [TRANSLATIONS.YOU_ARE_VIEWING]: 'Estás viendo',
      [BLOCKED_REASONS.SELF_ASSESSMENT]: 'Self-assessment',
      [BLOCKED_REASONS.MANUAL]: 'Manual',
      [BLOCKED_REASONS.VISIT_DOCTOR]: 'Bloqueo Medico',
      [TRANSLATIONS.TOTAL_REPORTS]: 'Reportes totales',
      [TRANSLATIONS.REPORTS]: 'Reportes',
      [TRANSLATIONS.UNIQUE]: 'Únicos',
      [TRANSLATIONS.UNIQUE_USERS]: 'Usuarios únicos',
      [TRANSLATIONS.AVERAGE_PER_USER]: 'Prom. por usuario',
      [TRANSLATIONS.AVERAGE_DAILY_PER_USER]: 'Prom. diario por usuario',
      [TRANSLATIONS.LAST_7_DAYS]: 'Últ. 7 días',
      [TRANSLATIONS.LAST_30_DAYS]: 'Últ. 30 días',
      [TRANSLATIONS.LAST_90_DAYS]: 'Últ. 90 días',
      [TRANSLATIONS.MONTH_TO_DATE]: 'Mes Corriente',
      [TRANSLATIONS.YEAR_TO_DATE]: 'Año corriente',
      [TRANSLATIONS.PRECIDING_PERIOD]: 'Period. ant.',
      [TRANSLATIONS.REPORT_HISTORY]: 'Evolución de reportes',
      [TRANSLATIONS.WHERE_ARE_YOU]: 'En dónde estás?',
      [TRANSLATIONS.ZONE]: 'Zona',
      [TRANSLATIONS.AGENCIA_DC]: 'CD',
      [TRANSLATIONS.CONTINUE]: 'Continuar',
      [TRANSLATIONS.NEW_REPORT]: 'Nuevo reporte',
      [TRANSLATIONS.REPORT_DATA]: 'Datos del ACI',
      [TRANSLATIONS.WHO_IS_REPORTING_IT]: 'Quien lo esta reportando?',
      [TICKET_TYPE.CONDITION]: 'Condición',
      [SECURITY_STATUS.SECURE]: 'Seguro',
      [SECURITY_STATUS.INSECURE]: 'Insegura',
      [TRANSLATIONS.WHO_COMMITTED_THE_ACT]: '¿Quién cometió el acto?',
      [TRANSLATIONS.IT_WAS_NOT_POSSIBLE_TO_IDENTIFY]: 'No fue posible identificarlo!',
      [TRANSLATIONS.IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT]: 'Será un reporte anónimo',
      [TRANSLATIONS.IT_IS_AN_ANONYMOUS_REPORT]: 'Es un reporte anónimo',
      [TRANSLATIONS.I_COULDNT_IDENTIFY_THE_PERSON]: 'No logré identificar a la persona',
      [TRANSLATIONS.SEARCH_THE_LIST_AGAIN]: 'Volver a buscar en la lista',
      [TRANSLATIONS.BACK_TO_SEARCH_BAR]: 'Regresar a buscar',
      [TRANSLATIONS.WHERE_DID_YOU_SEE]: '¿Dónde lo viste?',
      [TRANSLATIONS.WHERE_DID_YOU_SEE_IN_DC_AREA]: '¿Dónde lo viste?',
      [TRANSLATIONS.CD_AREA]: 'Área del CD*',
      [TRANSLATIONS.IN_WHICH_AREA_OF_DISTRIBUTION_CENTER]: '¿En qué área del Centro de Distribución?*',
      [TRANSLATIONS.SPECIFY_THE_PLACE_IN_THE_SUBAREA]: 'Especifica el lugar en la subárea',
      [TRANSLATIONS.TYPE_OF_RISK]: 'Tipo de riesgo',
      [TRANSLATIONS.RISK_GROUP]: 'Clasificación',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED]: '¿Qué riesgo se previene al realizarlo?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT]: '¿Qué riesgo se previene al realizarlo?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION]: '¿Qué riesgo se previene con esta condición segura?*',
      [TRANSLATIONS.WHO_HAD_THIS_BEHAVIOR]: '¿Quién cometió el acto?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_BEHAVIOR]: '¿Quién reportó el acto?',
      [TRANSLATIONS.WHO_HAD_THIS_CONDITION]: '¿Quién cometió la condición?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_CONDITION]: '¿Quién reportó la condición?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK]: '¿Quién reportó el riesgo ambiental?',
      [TRANSLATIONS.SELECT_RISK_TYPE]: 'Elige tipo de riesgo',
      [TRANSLATIONS.SELECT_RISK_GROUP]: 'Elige clasificación de riesgo',
      [TRANSLATIONS.DESCRIPTION]: 'Descripción',
      [TRANSLATIONS.OF_THE_ACT]: 'del acto',
      [TRANSLATIONS.OF_THE_CONDITION]: 'de la condición',
      [TRANSLATIONS.RISK_LEVEL_TRANSLATION]: 'Nivel de riesgo',
      [RISK_LEVEL.HIGH]: 'Alto',
      [RISK_LEVEL.LOW]: 'Bajo',
      [PRIORITY.MEDIUM]: 'Medio',
      [TRANSLATIONS.CORRECTIVE_ACTION]: 'Acción correctiva',
      [TRANSLATIONS.UPLOAD_REGISTRATION]: 'Subir registro',
      [TRANSLATIONS.UPLOAD_EVIDENCE]: 'Subir evidencia',
      [TRANSLATIONS.ACI_OVERVIEW]: 'Resumen ACI',
      [TRANSLATIONS.LEVEL]: 'Nivel',
      [TRANSLATIONS.FINISH_AND_SEND]: 'Terminar y enviar',
      [TRANSLATIONS.THANK_YOU]: 'Gracias',
      [TRANSLATIONS.WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE]: 'Con tu ayuda podemos prevenir más accidentes a futuro!',
      [TRANSLATIONS.SENT_SUCCESSFULLY]: 'Enviado exitosamente',
      [TRANSLATIONS.SIO_SUCCESSFULLY_SENT]: 'ACI enviado exitosamente',
      [TRANSLATIONS.THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS]: 'Gracias a ti podemos mejorar la seguridad entre nuestros colaboradores.',
      [TRANSLATIONS.SUCCESSFUL_REGISTRATION]: 'Registro exitoso!',
      [TRANSLATIONS.YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY]: 'Tú registro fue enviado con éxito',
      [TRANSLATIONS.THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT]: 'Tú registro fue enviado con éxito',
      [TRANSLATIONS.DOWNLOAD]: 'Descargar',
      [TRANSLATIONS.CERTIFICATES]: 'certificados',
      [TRANSLATIONS.BOTH]: 'ambos',
      [TRANSLATIONS.INCIDENTS]: 'ACIs',
      [TRANSLATIONS.INCIDENTS_PER_POSITION]: 'ACIs por posición',
      [TRANSLATIONS.INCIDENTS_NATIONAL]: 'ACIs (Nacional)',
      [TRANSLATIONS.ALL_ZONES]: 'Todas las Zonas',
      [TRANSLATIONS.DRVS]: "DRV's",
      [TRANSLATIONS.DISTRIBUTION_CENTERS]: 'Centro de distribución',
      [pluralize(TRANSLATIONS.DISTRIBUTION_CENTERS)]: 'Centros de Distribución',
      [TRANSLATIONS.ACIS]: 'ACIs',
      [TRANSLATIONS.LOGOUT]: 'Salir',
      [TRANSLATIONS.GO_TO_DASHBOARD]: 'Regresar al tablero',
      [TRANSLATIONS.DECEMBER]: 'Diciembre',
      [TRANSLATIONS.NOVEMBER]: 'Noviembre',
      [TRANSLATIONS.OCTOBER]: 'Octubre',
      [TRANSLATIONS.SEPTEMBER]: 'Septiembre',
      [TRANSLATIONS.AUGUST]: 'Agosto',
      [TRANSLATIONS.JULY]: 'Julio',
      [TRANSLATIONS.JUNE]: 'Junio',
      [TRANSLATIONS.MAY]: 'Mayo',
      [TRANSLATIONS.APRIL]: 'Abril',
      [TRANSLATIONS.MARCH]: 'Marzo',
      [TRANSLATIONS.FEBRUARY]: 'Febrero',
      [TRANSLATIONS.JANUARY]: 'Enero',
      [TRANSLATIONS.ALL_MONTHLY_OPTIONS]: 'Todos los meses',
      // external users module - start
      [TRANSLATIONS.EXTERNAL]: 'Externos',
      [TRANSLATIONS.INTERNAL]: 'Internos',
      [TRANSLATIONS.SEARCH_USERS]: 'Buscar usuarios',
      [TRANSLATIONS.ADD_USER_TO_DC]: 'Agregar usuario a',
      [TRANSLATIONS.USER_ALREADY_EXISTS]: 'Valida si ya existe el usuario',
      [TRANSLATIONS.DOES_USER_ACCOUNT]: '¿El usuario ya tiene cuenta de Safety360?',
      [TRANSLATIONS.YES_HE_HAS]: 'Si, ya tiene cuenta',
      [TRANSLATIONS.ENTER_EMAIL]: 'Escribe correo',
      [TRANSLATIONS.NO_CREATE_SCRATCH]: 'No, crear desde cero',
      [TRANSLATIONS.POSITION]: 'Puesto',
      [TRANSLATIONS.WORKS_AT]: 'Trabaja en',
      [TRANSLATIONS.TYPE_OF_COLLABORATOR]: 'Tipo de colaborador',
      [TRANSLATIONS.SELECT_DISTRIBUTION_CENTER]: 'Selecciona un Centro de Distribución',
      [TRANSLATIONS.CHOOSE]: 'ELEGIR',
      [TRANSLATIONS.PERMISSIONS]: 'Permisos',
      [TRANSLATIONS.ROUTINES]: 'Rutinas',
      [TRANSLATIONS.TRAININGS]: 'Capacitaciones',
      [TRANSLATIONS.ACCIDENTABILITY]: 'Accidentabilidad',
      [TRANSLATIONS.ANALYTICS]: 'Analíticos',
      [TRANSLATIONS.CANCEL]: 'Cancelar',
      [TRANSLATIONS.ADD]: 'Agregar',
      [TRANSLATIONS.ACCESS]: 'Acceso',
      [TRANSLATIONS.INFORMATION_NOT_AVAILABLE]: 'Información no disponible',
      [TRANSLATIONS.EDIT_INFORMATION]: 'Editar información',
      [TRANSLATIONS.DELETE_USER]: 'Eliminar usuario',
      [TRANSLATIONS.DELETE_USER_OPTION_MENU]: 'Eliminar usuario',
      [TRANSLATIONS.CONFIRM]: 'Confirmar',
      [TRANSLATIONS.ENTER_NAME]: 'Escribe nombre',
      [TRANSLATIONS.YES]: 'Si',
      [TRANSLATIONS.NO]: 'No',
      [TRANSLATIONS.USERS]: 'Usuarios',
      [TRANSLATIONS.CHECK_YOUR_EMAIL]: 'Por favor revise su correo electrónico.',
      [TRANSLATIONS.ASK_USER_WHICH_EMAIL]: 'Pregunta al usuario qué correo usó para registrarse en Safety360',
      [TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER]: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
      [TRANSLATIONS.USER_NOT_EXIST_CREATE_NEW]: 'Usuario no existe, verifica el correo o crea el usuario',
      [TRANSLATIONS.DELIVERY_MAN]: 'Repartidor',
      [TRANSLATIONS.SUPPLIER]: 'Proveedor',
      [TRANSLATIONS.WAREHOUSE_ASSISTANT]: 'Ayudante de bodega',
      [TRANSLATIONS.PEOPLE_AND_MANAGEMENT]: 'Gente y gestion',
      [TRANSLATIONS.FORKLIFT_DRIVER]: 'Montacarguista',
      // external users module - end
      [TRANSLATIONS.WEEKLY_REPORTS]: 'Reportes de ACIs',
      [TRANSLATIONS.BOXES]: 'cajos',
      [TRANSLATIONS.WHO_COMMITTED]: 'Quién cometió',
      [TICKET_TYPE.ACT]: 'Acto',
      [TRANSLATIONS.BEHAVIOR]: 'Acto',
      [TRANSLATIONS.DATE_REPORTED]: 'Inicio',
      [TRANSLATIONS.REPORTS_LIST]: 'Lista de ACIs',
      [pluralize(TRANSLATIONS.ALL)]: 'Todos',
      [TRANSLATIONS.BEHAVIORS]: 'Actos',
      [TRANSLATIONS.CONDITIONS]: 'Condiciones',
      [TRANSLATIONS.DOWNLOAD_LIST]: 'Descargar ACIs',
      [TRANSLATIONS.PLACE]: 'Lugar',
      [TRANSLATIONS.TOTAL]: 'Total',
      [TRANSLATIONS.OPENED]: 'Abiertos',
      [TRANSLATIONS.CLOSED]: 'Cerrados',
      [TRANSLATIONS.ASSIGNED]: 'Asignados',
      [TRANSLATIONS.CLOSE]: 'Cerrar',
      [TRANSLATIONS.REPORT]: 'Reportar',
      [TRANSLATIONS.ASSIGN]: 'Asignar',
      [TRANSLATIONS.INSIDE_DC]: 'Dentro de Ubicación',
      [TRANSLATIONS.OUTSIDE_DC]: 'Fuera de Ubicación',
      [TRANSLATIONS.ENVIRONMENTAL_RISK]: 'Riesgo ambiental',
      [pluralize(TRANSLATIONS.ENVIRONMENTAL_RISK)]: 'Riesgos Ambientales',
      [TRANSLATIONS.SAFETY_CERTIFICATES_T2]: 'Certificados de Seguridad T2',
      [pluralize(TRANSLATIONS.ACT)]: 'Actos',
      [TRANSLATIONS.DESCRIPTION_OF_THE_CONDITION]: 'Descripción de la condición',
      [TRANSLATIONS.DESCRIBE_THE_BEHAVIOR]: 'Describe el acto',
      [TRANSLATIONS.CONFIRM_USER_DELETION]: 'Seguro que quieres eliminar a',
      [TRANSLATIONS.PIN_CREATED_SUCCESSFULLY]: 'PIN generado exitosamente',
      [TRANSLATIONS.REGION]: 'Zona',
      [TRANSLATIONS.SELECT_REGION]: 'Elige zona',
      [TRANSLATIONS.BUSINESS_UNIT]: 'Regional',
      [TRANSLATIONS.SELECT_DC]: 'Elige CD',
      [TRANSLATIONS.SELECT]: 'Elige',
      [TRANSLATIONS.SELECT_SUBLOCATION]: 'Elige sub-locación',
      [TRANSLATIONS.SELECT_POSITION]: 'Elige una posición',
      [TRANSLATIONS.WRITE_YOUR_8_DIGIT_SAP_NUMBER]: 'Escribe tu #SAP de 8 dígitos',
      [TRANSLATIONS.WRITE_AN_8_DIGIT_SAP_NUMBER]: 'Escribe un #SAP de 8 dígitos',
      [TRANSLATIONS.SAP_SUCCESSFULLY_VALIDATED]: '#SAP validado correctamente',
      [TRANSLATIONS.PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER]: ' Asegúrate de que sean 8 dígitos por favor.',
      [TRANSLATIONS.TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER]: 'Para guardar histórico de tus reportes, escribe tu número SAP.',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT]: 'Si lo dejas vacío o incorrecto contará como reporte anónimo ☝🏼',
      [TRANSLATIONS.CLEAR]: 'Limpiar',
      [TRANSLATIONS.OVERVIEW]: 'Overview',
      [TRANSLATIONS.DIST_CENTERS]: 'Ubicaciones',
      [TRANSLATIONS.HOME]: 'Inicio',
      [TRANSLATIONS.BLOCK_UNBLOCK_SHORT]: 'Bloq./Desbloq.',
      [TRANSLATIONS.PRECEDING_PERIOD]: 'Periodo anterior',
      [TRANSLATIONS.AVERAGE]: 'Promedio',
      [TRANSLATIONS.PARTICIPATION]: 'Participación',
      [TRANSLATIONS.DAILY_AVG_PER_USER]: 'Prom. diario por usuario',
      [TRANSLATIONS.ALL_THE_BUS]: 'Todas las BUs',
      [TRANSLATIONS.ALL]: 'Todo',
      [TRANSLATIONS.BU]: 'País',
      [TRANSLATIONS.PASSWORD_TOO_SHORT]: 'Contraseña muy corta',
      [TRANSLATIONS.PASSWORD_NO_MATCH]: 'Contraseñas no son iguales',
      [TRANSLATIONS.DO_NOT_USE_YOUR_OLD_PASSWORD]: 'No utilices tu antigua contraseña',
      [TRANSLATIONS.ROUTINES_FOLLOWUP]: 'Seguimiento de rutinas',
      [TRANSLATIONS.WEEK]: 'Semana',
      [TRANSLATIONS.TRAININGS_PERCENTAGE]: '% DE CAPACITACIÓN',
      [TRANSLATIONS.SIF_ROUTE_PERCENTAGE]: '% DE RUTAS',
      [TRANSLATIONS.COURSES]: 'Cursos',
      [TRANSLATIONS.HC_TO_BE_TRAINED]: 'HC a capacitar',
      [TRANSLATIONS.TRAINED]: 'Capacitados',
      [TRANSLATIONS.IMAGE]: 'Imagen',
      [TRANSLATIONS.USER_ACCESS_DISCLAIMER]: 'Si no ves las ubicaciones correctas comunicate con un super usuario. Puedes pedir su contacto con el equipo de soporte en el chat de abajo a la derecha.',
      [TRANSLATIONS.USER_MISSING_DISCLAIMER]: 'Si hacen falta usuarios internos, escribe a soporte en el chat de abajo a la derecha.',
      [TRANSLATIONS.SECURE_CONDITION_DISCLAIMER]: 'Las secciones de abierto, cerrados y asignados no incluyen actos o condiciones seguras',
      [TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT]: 'No tienes ninguna ubicación asignada, comunicate con un Super Usuario. Puedes pedir el contacto del Super Usuario con el Equipo de Soporte en el chat de abajo a la derecha.',
      [TRANSLATIONS.DOWNLOAD_ALL]: 'Descargar Todo',
      [TRANSLATIONS.INCIDENTS_PER_AREA]: 'ACIs por área',
      [TRANSLATIONS.RESET]: 'Limpiar',
      [TRANSLATIONS.SAFE]: 'Seguros',
      [TRANSLATIONS.PLANT]: 'CD/Planta',
      [TRANSLATIONS.ID]: 'ID',
      [TRANSLATIONS.SHARP]: 'Sharp',
      [TRANSLATIONS.STATUS]: 'Estado',
      [TRANSLATIONS.REASON]: 'Motivo bloqueo',
      [TRANSLATIONS.ACTIONS]: 'Acciones',
      [TRANSLATIONS.BLOCKED]: 'Bloqueado',
      [TRANSLATIONS.MEDIACAL_EXAMINATION]: 'Examen médico',
      [TRANSLATIONS.NO_DATA]: 'No hay Data',
      [TRANSLATIONS.NO_DATA_FOUND_SO_FAR]: 'Hasta el momento no se encontraron datos para esta sección',
      [TRANSLATIONS.CURRENT_PROGRESS]: 'Prog. Actual',
      [TRANSLATIONS.TOTAL_PROGRESS]: 'Progreso Total',
      [TRANSLATIONS.LAST]: 'Última',
      [TRANSLATIONS.DOWNLOAD_ROUTINES_DB]: 'Descargar la base de datos de Rutinas',
      [TRANSLATIONS.SEARCH_LOCATION]: 'Ubicación de búsqueda',
      [TRANSLATIONS.NO_MATCHES_FOUND]: 'No se encontraron coincidencias',
      [TRANSLATIONS.CONSEQUENCES]: 'Consecuencias',
      [TRANSLATIONS.SELECT_SUBZONE_OR_DISTRIBUTION_CENTER]: 'Por favor escoge una UEN o CD para mostrar el progreso por usuario',
      [TRANSLATIONS.CRITICAL_CLIENTS]: 'Clientes críticos',
      [TRANSLATIONS.DIARIA_LOGÍSTICA]: 'Diaria Logística',
      [TRANSLATIONS.RUTINA_MATUTINA_DE_REPARTO]: 'Rutina matutina de reparto (delivery)',
      [TRANSLATIONS.RUTINA_DE_CAMBIO_DE_TURNO]: 'Rutina de cambio de turno (warehouse)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_ALMACÉN]: 'Rutina semanal de Almacén',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_DIST]: 'Rutina semanal de Distribución',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PEOPLE]: 'Rutina semanal de People',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_FLOTA]: 'Rutina semanal de Flota',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PLANNING]: 'Rutina semanal de Planning (Financiera VLC)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_SAFETY]: 'Rutina semanal de Safety (Financiera VLC)',
      [TRANSLATIONS.RUTINE_SEMANAM_DE_GESTION]: 'Rutina semanal de Gestión',
      [TRANSLATIONS.AT_HOME]: 'En casa',
      [TRANSLATIONS.HOSPITAL]: 'Hospital',
      [TRANSLATIONS.STABLE]: 'Estable',
      [TRANSLATIONS.STABLE_WITH_OXYGEN]: 'Estable con oxígeno',
      [TRANSLATIONS.SERIOUS_WITH_OXYGEN]: 'Serio con oxígeno',
      [TRANSLATIONS.SERIOUS_WITHOUT_OXYGEN]: 'Serio sin oxígeno',
      [TRANSLATIONS.LAST_UPDATED_ON_MONTH_DATE_DAY]: 'Últ. actualización el {{date}} de {{month}}, hace {{day}} días.',
      [TRANSLATIONS.LAST_UPDATED_TODAY]: 'Última actualización hoy.',
      [TRANSLATIONS.UPDATE_USER_STATUS_TODAY_MONTH_DATE]: 'Actualizar estado de {{user}} del día de hoy {{date}} de {{month}}.',
      [TRANSLATIONS.WHERE_IS_USER]: '¿En dónde se encuentra {{user}}?',
      [TRANSLATIONS.REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS]: 'Recuerda que enviar esta información es clave para que podamos brindarles el mayor apoyo posible.',
      [TRANSLATIONS.SAVE]: 'Guardar',
      [TRANSLATIONS.ADD_COMMENT]: 'Agregar comentario',
      [TRANSLATIONS.COMMENT_CREATED_AT]: '{{date}} a las {{time}}',
      [TRANSLATIONS.COMMENT_SHOULD_BE_UNDER_N_CHARACTER]: 'El comentario debe tener menos de {{maxSize}} caracteres',
      [TRANSLATIONS.EDIT]: 'Cambiar',
      [TRANSLATIONS.DELETE]: 'Eliminar',
      [TRANSLATIONS.DELETING]: 'Borrando',
      [TRANSLATIONS.PARTICIPANT]: 'Participante',
      [TRANSLATIONS.OWNER]: 'Dueño',
      [TRANSLATIONS.IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW]: 'De no ser necesario, regresa a actualizar hasta el día de mañana.',
      [TRANSLATIONS.STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH]: 'Estado actualizado al día de hoy {{date}} de {{month}}',
      [TRANSLATIONS.UPDATED_TODAY_AT_TIME]: 'Actualizado hoy a las {{time}}.',
      [TRANSLATIONS.YOUR_QUARANTINE_HAS]: '¡Tú cuarentena ha',
      [TRANSLATIONS.CONCLUDED]: 'concluido!',
      [TRANSLATIONS.PHYSICAL_STATE]: 'Estado físico',
      [TRANSLATIONS.PILLAR]: 'Pilar',
      [TRANSLATIONS.SAFETY]: 'Safety',
      [TRANSLATIONS.MANAGEMENT]: 'Gestión',
      [TRANSLATIONS.PLANNING]: 'Planning',
      [TRANSLATIONS.DISTRIBUTION]: 'Distrib.',
      [TRANSLATIONS.FLEET]: 'Flota',
      [TRANSLATIONS.STOCK]: 'Almacén',
      [TRANSLATIONS.PEOPLE]: 'People',
      [TRANSLATIONS.WAREHOUSE]: 'Warehouse',
      [TRANSLATIONS.NOTE]: 'Nota',
      [TRANSLATIONS.SEARCH_KPI]: 'Buscar KPI\'s',
      [TRANSLATIONS.NO_RESULTS_FOUND]: 'No hay resultados',
      [TRANSLATIONS.SEARCH_TO_ADD_USERS]: 'Buscar Para Agregar Usuarios',
      [TRANSLATIONS.ACTION_LOG_TABLE_FOR_LOCATION_NAME]: 'Tablero de tareas de {{locationName}}',
      [TRANSLATIONS.VIOLENCE_PREVENTION]: 'Prevención de violencia',
      [TRANSLATIONS.DELETE_FILTERS]: 'Borrar filtros',
      [TRANSLATIONS.ALL_ROUTINES]: 'Todas las rutinas',
      [TRANSLATIONS.ALL_KPIS]: 'Todos los KPIs',
      [TRANSLATIONS.ALL_USERS]: 'Todos los usuarios',
      [TRANSLATIONS.SELECT_THE_ROUTINE_YOU_WANT_TO_SEE]: 'Selecciona la rutina que deseas ver',
      [TRANSLATIONS.SELECT_THE_KPIS_YOU_WANT_TO_SEE]: 'Selecciona los KPIs que deseas ver',
      [TRANSLATIONS.SELECT_THE_USERS_YOU_WANT_TO_SEE]: 'Selecciona los usuarios que deseas ver',
      [TRANSLATIONS.NO_DUE_DATE_SELECTED]: 'Sin fecha compromiso',
      [TRANSLATIONS.DELETE_COMMENT]: 'Eliminar comentario',
      [TRANSLATIONS.CLICK_HERE_DROP_TO_UPLOAD]: '¡Haz clic aquí o deja un archivo para cargarlo!',
      [TRANSLATIONS.DROP_IT_HERE]: 'Déjalo aquí',
      [TRANSLATIONS.FILE_TYPE_NOT_ACCEPTED_SORRY]: 'Lo sentimos, tipo de archivo inaceptable',
      [TRANSLATIONS.MAXIMUM_ALLOWED_FILE_SIZE]: 'Tamaño de archivo máximo : {{size}}',
      [TRANSLATIONS.REMOVE_FILE]: 'Remover archivo',
      [TRANSLATIONS.UPLOADING]: 'Subiendo',
      [TRANSLATIONS.UPLOAD]: 'Subir',
      [TRANSLATIONS.ERROR]: 'Error del servidor',
      [TRANSLATIONS.RETRY]: 'Vuelve a intentar',
      [TRANSLATIONS.FILE_IS_TOO_LARGE]: 'El archivo es demasiado grande',
      [TRANSLATIONS.SERVER_ERROR]: 'Error del servidor',
      [TRANSLATIONS.PROCESSING_YOUR_FILE]: 'Procesando tu archivo',
      [TRANSLATIONS.PROCESSING_FAILED_WITH_MESSAGE]: 'Procesamiento fallido {{msg}}',
      [TRANSLATIONS.CLICK_TO_VISIT]: 'Haz clic para visitar {{location}}',
      [TRANSLATIONS.REUPLOAD]: 'Volver a subir',
      [TRANSLATIONS.FINISHED_FILE_PROCESSING]: 'Procesamiento de archivos terminado',
      [TRANSLATIONS.DONE]: 'Listo',
      [TRANSLATIONS.ALL_ITEMS_ARE_SELECTED]: 'Todos elementos estan seleccionados',
      [TRANSLATIONS.CLEAR_SEARCH]: 'Borrar búsqueda',
      [TRANSLATIONS.NO_OPTIONS]: 'No hay opciones ',
      [TRANSLATIONS.SELECT_ALL]: 'Seleccionar todo',
      [TRANSLATIONS.SELECT_ALL_FILTERED]: 'Seleccionar todo (filtrado)',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_HEADING]: 'Cumplimiento de rutinas',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLETED_PERCENTAGE]: '% rutinas',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLIANCE]: 'Cumplimiento',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_EXECUTED]: 'Ejecutadas',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PENDING]: 'Pendientes',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_RANKING]: 'Ranking de CDs en MAZ',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PLANNED]: 'Planes',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_SEARCH]: 'Buscar por GRO...',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_REGION]: 'País / Región',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_BU]: 'Por BU',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_CD]: 'DC',
      [TRANSLATIONS.TOOLKIT_HEADING]: 'Prevención de violencia',
      [TRANSLATIONS.TOOLKIT_PREVIOUS_MONTH]: 'Mes actual',
      [TRANSLATIONS.META]: 'Meta',
      [TRANSLATIONS.NOTES]: 'Notas para',
      [TRANSLATIONS.COMMENT_BY]: 'Comentario escrito por',
      [TRANSLATIONS.LATEST_EDIT]: 'Última edición',
      [TRANSLATIONS.SAVE_NOTE]: 'Guardar nota',
      [TRANSLATIONS.SIF_STATISTICS]: 'Estadistics SIF',
      [TRANSLATIONS.USER_TYPE]: 'Tipo de usuario',
      [TRANSLATIONS.USER_ROLE_TRANSLATION]: 'Rol de usuario',
      [TRANSLATIONS.UPDATE]: 'Actualizar',
      [TRANSLATIONS.PASSWORD_RECOVERY]: 'Recuperacion de contraseña',
      [TRANSLATIONS.UPDATE_YOUR_PASSWORD_TO_ACCESS]: 'Actualiza tu contraseña para acceder a la plataforma',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED]: 'Tú contraseña fue restaurada exitosamente.',
      [TRANSLATIONS.YOU_CAN_USE_THE_APP_AGAIN]: '¡Ya puedes volver a utilizar la app!',
      [TRANSLATIONS.REDIRECT_TIMEOUT]: 'Favor de esperar un momento mientras te redirigimos. Si después de 10 segundos no has sido redireccionado, favor de hacer click',
      [TRANSLATIONS.HERE]: 'Aquí',
      [TRANSLATIONS.PREVENTION_CAMPAIGNS]: 'Campaña Preventiva',
      [TRANSLATIONS.BEST_PRACTICES]: 'Buenas Prácticas',
      [TRANSLATIONS.MONTHLY_TOTAL]: 'Total Mensual',
      [TRANSLATIONS.BOARDS]: 'Tableros',
      [TRANSLATIONS.OWD]: 'OWDs',
      [TRANSLATIONS.PDT]: 'PDTs',
      [TRANSLATIONS.SAFETY_NEWS]: 'Noticias Safety',
      [TRANSLATIONS.TOP]: 'Máximo',
      [TRANSLATIONS.BOTTOM]: 'Minimo',
      [TRANSLATIONS.SELECT_MONTH]: 'Seleccionar Mes',
      [TRANSLATIONS.PYRAMID]: 'PIRÁMIDE',
      [TRANSLATIONS.COUNTRY]: 'País',
      [TRANSLATIONS.PLEASE_UPLOAD_A_FILE]: 'Por favor, sube un archivo',
      [TRANSLATIONS.LAST_UPDATED_ON]: 'Última actualización el {{monthAndYear}}',
      [TRANSLATIONS.TELEMETRY]: 'Telemetría',
      [TRANSLATIONS.CURRENT_MONTH]: 'Mes actual',
      [TRANSLATIONS.BY_CATEGORY]: 'Por categoría',
      [TRANSLATIONS.TELEMETRY_BY_BU]: 'Telemetría por BU',
      [TRANSLATIONS.REGULAR_SPEEDING]: 'Exceso de velocidad regular',
      [TRANSLATIONS.CRITICAL_SPEEDING]: 'Exceso de velocidad crítico',
      [TRANSLATIONS.CURVE_SPEEDING]: 'Exceso de velocidad en curva',
      [TRANSLATIONS.HARSH_TURNING]: 'Giro brusco',
      [TRANSLATIONS.SEATBELT]: 'Cinturón de seguridad',
      [TRANSLATIONS.VEHICLE]: 'Vehículo',
      [TRANSLATIONS.ACTION_LOGS]: 'Planes de Acción',
      [TRANSLATIONS.AMBIENTAL_RISKS]: 'Riesgos Ambientales',
      [TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING]: 'Planeación de la Producción',
      [TRANSLATIONS.BY_BU]: 'por BU',
      [TRANSLATIONS.BY_POSITION]: 'por Posicion',
      [TRANSLATIONS.BY_ROUTINES]: 'por Rutina',
      [TRANSLATIONS.CLICK_HERE]: 'Click Aquí!',
      [TRANSLATIONS.BY]: 'por',
      [TRANSLATIONS.QR_CODE]: 'Código QR',
      [TRANSLATIONS.SEARCH_ROUTINE]: 'Buscar rutina',
      [TRANSLATIONS.MANAGEMENT_TOOLS]: 'Herramienta de gestión',
      [TRANSLATIONS.SIF_ROUTE]: 'Ruta SIF',
      [TRANSLATIONS.SIF_COMPLANICE]: 'Cumplimiento SIF',
      [TRANSLATIONS.SAFETY_TRAINING]: 'Safety Training',
      [TRANSLATIONS.INVALID_CREDENTIALS_TRY_AGAIN]: 'Credenciales invalidas. Intenta otra vez!',
      [TRANSLATIONS.INCORRECT_EMAIL_PASSWORD]: 'Correo/Contraseña incorrecto',
      [TRANSLATIONS.THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG]: 'No hay nota para esta tarea...',
      [TRANSLATIONS.THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET]: 'Todavía no hay tareas en esta columna.',
      [TRANSLATIONS.ADD_ACTION_LOG]: 'Agregar tarea',
      [TRANSLATIONS.OPEN]: 'Abierto',
      [TRANSLATIONS.IN_PROGRESS]: 'En progreso',
      [TRANSLATIONS.IN_REVIEW]: 'En revisión',
      [TRANSLATIONS.COMPLETED]: 'Completado',
      [TRANSLATIONS.WRITE_ACTION_HERE]: 'Escribir acción aquí...',
      [TRANSLATIONS.SUCCESS_CRITERIA]: 'CRITERIO DE ÉXITO',
      [TRANSLATIONS.WRITE_SUCCESS_CRITERIA_HERE]: 'Escribir criterio de éxito aqui...',
      [TRANSLATIONS.REVIEW_IN]: 'REVISAR EN',
      [TRANSLATIONS.SAVE_ACTION_LOG]: 'Guardar tarea',
      [TRANSLATIONS.CREATE_ACTION_LOG]: 'Crear tarea',
      [TRANSLATIONS.SHOULD_BE_CLOSED_TODAY]: 'Debe cerrarse hoy',
      [TRANSLATIONS.SHOULD_HAVE_BEEN_CLOSED]: 'Debió cerrarse hace',
      [TRANSLATIONS.DAY]: 'día',
      [TRANSLATIONS.DAYS]: 'días',
      [TRANSLATIONS.TO_BE_CLOSED]: 'para el cierre',
      [TRANSLATIONS.THERE_ARE_NO_COMMENTS_YET]: 'No hay comentarios todavía',
      [TRANSLATIONS.CREATED]: 'Creado',
      [TRANSLATIONS.DUE_DATE]: 'Fecha compromiso',
      [TRANSLATIONS.SINCE]: 'desde',
      [TRANSLATIONS.IN]: 'en',
      [TRANSLATIONS.WRITE_A_NOTE]: 'Escribir una nota',
      [TRANSLATIONS.WAIT_A_MOMENT]: 'Espera un momento',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE]: 'Si sales sin guardar tus cambios estos se perderan ¿Deseas permanecer aquí?',
      [TRANSLATIONS.LEAVE_WITHOUT_SAVE]: 'Salir sin guardar',
      [TRANSLATIONS.DELETE_CARD]: 'Eliminar Tarjeta',
      [TRANSLATIONS.NOT_ENOUGH_PERMISSIONS_TO_ACCESS]: 'Sin acceso a web. Favor de contactar a un Super Usuario',
      [TRANSLATIONS.WEB_ACCESS]: 'Acceso web',
      [TRANSLATIONS.INDICATORS]: 'Indicadores',
      [TRANSLATIONS.DVR_CENTRAL_BAJIO]: 'DRV Central Bajío ',
      [TRANSLATIONS.METROPOLITAN_SBU]: 'UEN Metropolitana',
      [TRANSLATIONS.METROPOLITAN]: 'Metropolitana',
      [TRANSLATIONS.CD_SAN_PABLO]: 'CD San Pablo',
      [TRANSLATIONS.DELIVERY_DRIVER]: 'Conductor de reparto',
      [TRANSLATIONS.FORKLIFTERS]: 'Montacarguistas',
      [TRANSLATIONS.WAREHOUSE_AUXILIAR]: 'Auxiliar de almacén',
      [TRANSLATIONS.DELIVERY_AUXILIAR]: 'Auxiliar de reparto',
      [TRANSLATIONS.SKAP]: 'SKAP',
      [TRANSLATIONS.SORRY_THERE_WAS_AN_ERROR]: 'Lo sentimos, hubo un error',
      [TRANSLATIONS.RELOAD_PAGE]: 'Recargar página',
      [TRANSLATIONS.IMAPACTED_HC]: 'HC impactado',
      [TRANSLATIONS.FUNCTIONAL_INDUCTION]: 'Inducción funcional',
      [TRANSLATIONS.TECHNICAL_SKILLS]: 'Habilidades técnicas',
      [TRANSLATIONS.AUTONOMOUS_SKILLS]: 'Habilidades autónomas',
      [TRANSLATIONS.FUNCTIONAL]: 'Funcional',
      [TRANSLATIONS.TECHNICAL]: 'Técnicos',
      [TRANSLATIONS.AUTONOMOUS]: 'Autónomos',
      [TRANSLATIONS.AUTONOMY_LEVEL]: 'Nivel de autonomía',
      [TRANSLATIONS.HEAD_COUNT]: 'Head count',
      [TRANSLATIONS.RAMP_UP]: 'Ramp up',
      [TRANSLATIONS.AREA]: 'Área',
      [TRANSLATIONS.HC_TOTAL]: 'HC total',
      [TRANSLATIONS.DOWNLOAD_EXCEL]: 'Descarga Excel',
      [TRANSLATIONS.ALL_THE_TEAM]: 'Todo el equipo',
      [TRANSLATIONS.NEW_COLLABORATORS]: 'Colaboradores nuevos',
      [TRANSLATIONS.OLD_COLLABORATORS]: 'Colaboradores antiguos',
      [TRANSLATIONS.DELAY_TASK]: 'Tareas atrasadas',
      [TRANSLATIONS.IN_WAREHOUSE]: 'En CD',
      [TRANSLATIONS.IN_FACTORY]: 'En planta',
      [TRANSLATIONS.LOCATION]: 'Ubicación',
      [TRANSLATIONS.CONDITION_WAS_CLOSED_IMMEDIATELY]: 'La condición fue cerrada de inmediato',
      [TRANSLATIONS.COULD_IT_BE_SIF]: '¿Podría ser un SIF?',
      [TRANSLATIONS.SIF_PRECURSOR]: 'SIF Precursor',
      [TRANSLATIONS.SIF_POTENTIAL]: 'SIF Potencial',
      [TRANSLATIONS.DEPARTMENT]: 'Departamento',
      [TRANSLATIONS.SAP_SHARP]: '#SAP/SHARP',
      [TRANSLATIONS.CREATE_AN_ACCOUNT]: 'Crear una cuenta',
      [TRANSLATIONS.DONT_HAVE_AN_ACCOUNT]: 'No tienes cuenta',
      [TRANSLATIONS.ALREADY_REGISTERED]: 'Ya estas registrado',
      [TRANSLATIONS.REGISTER_ON]: 'Registrate en',
      [TRANSLATIONS.REGISTER]: 'Registrar',
      [TRANSLATIONS.TERMS_AND_CONDITIONS]: 'He leído y acepto los terminos y condiciones',
      [TRANSLATIONS.FOLLOW_THE_INSTRUCTIONS_RECEIVED]: 'Sigue las instrucciones que recibiste en tu correo para crear una contraseña',
      [TRANSLATIONS.TOO_SHORT]: 'muy corto',
      [TRANSLATIONS.YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS]: 'Debes aceptar los terminos y condiciones',
      [TRANSLATIONS.ENTER_A_CORRECT_EMAIL_FORMAT]: 'ingresar un formato de email correcto',
      [TRANSLATIONS.OPERATION]: 'Operación',
      [TRANSLATIONS.CATEGORY]: 'Categoría',
      [TRANSLATIONS.PROCEDURE]: 'Procedimiento',
      [TRANSLATIONS.PRIORITY_T]: 'Prioridad',
      [TRANSLATIONS.LOGISTICS]: 'Logística',
      [TRANSLATIONS.YOU_MUST_ENTER_YOUR_OPERATION_TYPE]: 'Debes seleccionar un tipo de operación',
      [TRANSLATIONS.DISTRIBUTION_CENTER]: 'Centro de distribución',
      [TRANSLATIONS.FACTORY]: 'Planta',
      [TRANSLATIONS.AN_ERROR_HAS_OCCURRED]: 'Ha ocurrido un error.',
      [TRANSLATIONS.FILE_UPLOADED_SUCCESSFULLY]: 'Se cargó correctamente el archivo.',
      [TRANSLATIONS.COPY_QR_CODE]: 'Copiar codigo QR',
      [TRANSLATIONS.SUBAREA]: 'Subárea',
      [TRANSLATIONS.NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE]: 'Nota: Los archivos que se suban no se reflejarán inmediatamente, una vez que subas todos tus archivos por favor actualiza la página.',
      [TRANSLATIONS.YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES]: 'Tu archivo se ha comenzado a cargar, puede tardar unos minutos.',
      [TRANSLATIONS.USER_UPDATED_SUCCESSFULLY]: 'Usuario actualizado con éxito.',
      [TRANSLATIONS.UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE]: 'El usuario actualizado ahora no tiene ningún rol, asigne un permiso al usuario en la pestaña de permisos.',
      [TRANSLATIONS.SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE]: 'Busca el usuario por código QR para configurar',
      [TRANSLATIONS.SELECT_LOCATION]: 'Selecciona una ubicación',
      [TRANSLATIONS.OPERATIONAL]: 'Operativo',
      [TRANSLATIONS.CORPORATE]: 'Corporativo',
      [TRANSLATIONS.DOWNLOAD_DATA]: 'Descargar datos',
      [TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED]: 'Se descargarán los datos de la(s) ubicación(es) que estén filtradas en la barra superior de filtrado.',
      [TRANSLATIONS.SELECT_DOCUMENT]: 'Selecciona documento',
      [TRANSLATIONS.SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD]: 'Selecciona el documento que deseas descargar',
      [TRANSLATIONS.DOWNLOADING_DATA]: 'Descargando datos...',
      [TRANSLATIONS.CHOOSE_HERE]: 'Elige aquí',
      [TRANSLATIONS.WITHOUT_POSITION]: 'Sin posición',
      [TRANSLATIONS.RETRY_AGAIN]: 'Reintentar',
      [TRANSLATIONS.SOMETHING_WENT_WRONG]: 'Algo falló',
      [TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE]: 'Error en la descarga. Reintenta la descarga por favor.',
      [TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE]: 'Los datos están tardando mucho en descargar, te será enviado un link de descarga a tu correo para poder continuar.',
      [TRANSLATIONS.JOB_TITLE]: 'Puesto',
      [TRANSLATIONS.UNBLOCKED]: 'Desbloqueado',
      [TRANSLATIONS.NEXT]: 'Siguiente',
      [TRANSLATIONS.PREVIOUS]: 'Anterior',
      [TRANSLATIONS.TOTAL_PAGES]: 'Paginas Totales',
      [TRANSLATIONS.REMOVE_FILTERS]: 'Quitar filtros',
      [TRANSLATIONS.UPLOAD_TEMPLATE]: 'Subir template',
      [TRANSLATIONS.ADD_NEW_USER]: 'Agregar usuario',
      [TRANSLATIONS.DONT_FORGET_TO_SET_AN_ACCESS_TO_LOCATION]: 'No olvide configurar el acceso a {location}',
      [TRANSLATIONS.UPDATE_TEMPLATE]: 'Actualizar Plantilla',
      [TRANSLATIONS.ERROR_TO_SAVE_CHANGES_THE_USER_NEEDS_THE_ACCESS_TO_BE_SAME_AS_WORKS_AT_SELECTED]: 'Error al guardar cambios: El usuario necesita tener el mismo acceso que su lugar de trabajo.',
      [TRANSLATIONS.USER_DISABLED_CONTACT_TO_YOUR_SUPERVISOR_OR_SUPPORT]: 'Usuario dado de baja, comunícate con tu superior o soporte.',
      [TRANSLATIONS.NO_IMAGE]: 'Sin imagen',
      [TRANSLATIONS.START_DATE]: 'Fecha de inicio',
      [TRANSLATIONS.END_DATE]: 'Fecha final',
      [TRANSLATIONS.SEARCH_BY_NAME]: 'Buscar por Nombre',
      [TRANSLATIONS.SHARP_AND_DESCRIPTION]: 'Sharp y descripción',
      [TRANSLATIONS.NO_RECORDS_FOUND]: 'No se encontraron registros',
      [TRANSLATIONS.DO_NOT_USE_ONE_OF_YOUR_OLD_PASSWORDS]: 'No utilices una de tus contraseñas antiguas',
      [TRANSLATIONS.TRAINING]: 'Capacitación',
      [TRANSLATIONS.CAPITAL_LETTER]: 'Letra Mayúscula',
      [TRANSLATIONS.LOWER_CASE]: 'Letra Minúscula',
      [TRANSLATIONS.NUMBER]: 'Numero',
      [TRANSLATIONS.SPECIAL_CHARACTER]: 'Carácter especial',
      [TRANSLATIONS.WELCOME_TO]: 'Bienvenido a',
      [TRANSLATIONS.CONTAIN]: 'Contener',
      [TRANSLATIONS.PASSWORD_MUST]: 'Tu contraseña debe',
      [TRANSLATIONS.VERIFY_PASSWORD_CRITERIA]: 'Verifica las condiciones de la contraseña',
      [TRANSLATIONS.REPORT_SECTION]: 'Sección de Reporte',
      [TRANSLATIONS.EXECUTION]: 'Ejecución',
      [TRANSLATIONS.RESULTS]: 'Resultados',
      [TRANSLATIONS.TRUCKS]: '5s Camiones',
      [TRANSLATIONS.USER_PROGRESS]: 'Adherencia por usuario',
      [TRANSLATIONS.CLOSING_IMAGE]: 'Imagen de cierre',
      [TRANSLATIONS.DPO_ACADEMY]: 'DPO Academia',
      [TRANSLATIONS.DPO_ACADEMY_MATERIAL]: 'Material DPO Academy',
      [TRANSLATIONS.SEE_MORE]: 'Ver más',
      [TRANSLATIONS.SEE_LESS]: 'Ver menos',
      [TRANSLATIONS.CHANGED_ROLE_TO]: 'Rol cambiado de {{role1}} ({{from}}) a {{role2}} ({{to}})',
      [TRANSLATIONS.SECTOR]: 'Sector',
      [TRANSLATIONS.YEAR]: 'Año',
      [TRANSLATIONS.MONTH]: 'Mes',
      [TRANSLATIONS.SUMMARY]: 'Resumen',
      [TRANSLATIONS.ERROR_GETTING_DISTRIBUTION_CENTER_INFO]: 'Error al obtener la informacion del centro de distribución',
      [TRANSLATIONS.PLEASE_SELECT_A_BU_TO_SHOW_CHARTS]: 'Favor de seleccionar una BU para mostrar los gráficos',
      [TRANSLATIONS.ROUTINE_EFFECTIVENESS]: 'Efectividad de rutinas',
      [TRANSLATIONS.EXTRA_MATERIAL]: 'Material extra',
      [TRANSLATIONS.ADHERENCE]: 'Adherencia',
      [TRANSLATIONS.WORKSTATIION_EFFECTIVENESS]: 'Efectividad de Workstations',
      [TRANSLATIONS.DOWNLOAD_HC]: 'Descargar HC',
      [TRANSLATIONS.UNSAFE_CONDITION]: 'Condición insegura',
      [TRANSLATIONS.SAFE_CONDITION]: 'Condición segura',
      [TRANSLATIONS.UNSAFE_ACT]: 'Acto inseguro',
      [TRANSLATIONS.SAFE_ACT]: 'Acto seguro',
      [TRANSLATIONS.TO_SEE_THE_DETAILS_OF_AN_ACI_CLICK_ON_A_CARD]: 'Para ver el detalle de un ACI da click en una tarjeta.',
      [TRANSLATIONS.NO_SIF]: 'No SIF',
      [TRANSLATIONS.TYPE_OF_REPORT]: 'Tipo de ACIS',
      [TRANSLATIONS.SEARCH_BY_FOLIO]: 'Buscar por folio',
      [TRANSLATIONS.SELECT_DATE]: 'Seleccionar fecha',
      [TRANSLATIONS.NO_PHOTOS]: 'Sin fotos',
      [TRANSLATIONS.GOLDEN_RULE]: 'Regla de oro',
      [TRANSLATIONS.IT_DOES_BREAK]: 'Incumple',
      [TRANSLATIONS.IT_DOES_NOT_BREAK]: 'Cumple',
      [TRANSLATIONS.DO_YOU_WANT_TO_CLOSE_THE_CONDITION_AT_THIS_TIME]: '¿Deseas cerrar la condición en este momento?',
      [TRANSLATIONS.WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_CONDITION]: 'Escribe aquí la acción tomada para cerrar la condición',
      [TRANSLATIONS.WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_ACT]: 'Escribe aquí la acción tomada para cerrar el acto',
      [TRANSLATIONS.CLOSE_LATER]: 'Cerrar después',
      [TRANSLATIONS.CLOSE_RIGHT_NOW]: 'Cerrar ahora mismo',
      [TRANSLATIONS.CONDITION_REGISTERED_SUCCESSFULLY]: '¡Condición registrada exitosamente!',
      [TRANSLATIONS.ACT_REGISTERED_SUCCESSFULLY]: '¡Acto registrado exitosamente!',
      [TRANSLATIONS.CONDITION_CLOSED_SUCCESSFULLY]: '¡Condición cerrada exitosamente!',
      [TRANSLATIONS.THE_CONDITION_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT]: 'La condición fue registrada y será enviada al departamento correspondiente.',
      [TRANSLATIONS.THE_ACT_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT]: 'El acto fue registrado y será enviado al departamento correspondiente.',
      [TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED]: 'La condición fue registrada y cerrada de manera exitosa.',
      [TRANSLATIONS.START_ANOTHER_REPORT]: 'Comenzar otro reporte',
      [TRANSLATIONS.GO_TO_SAFETY]: 'Ir a Safety',
      [TRANSLATIONS.RETURN_SIO]: 'Regresar ACI',
      [TRANSLATIONS.CLOSE_SIO]: 'Cerrar ACI',
      [TRANSLATIONS.UNASSIGNED_SIOS]: 'ACIs sin asignar',
      [TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_SIO]: 'Escribe aquí la razón para regresar este ACI',
      [TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_ACT]: 'Escribe aquí el motivo de la devolución de este acto.',
      [TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_CONDITION]: 'Escriba aquí el motivo de la devolución de esta condición.',
      [TRANSLATIONS.RETURN_SIO_QUESTION_TEXT]: '¿Regresar ACI?',
      [TRANSLATIONS.RETURN_ACT]: '¿Regresar Acto?',
      [TRANSLATIONS.RETURN_CONDITION]: '¿Regresar Condición?',
      [TRANSLATIONS.IF_THE_SIO_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN]: 'Si el ACI no te pertenece, presiona ”Si, regresar”.',
      [TRANSLATIONS.IF_THE_CONDITION_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN]: 'Si la condición no te pertenece, presiona ”Si, regresar”.',
      [TRANSLATIONS.IF_THE_ACT_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN]: 'Si el acto no te pertenece, presiona ”Si, regresar”.',
      [TRANSLATIONS.YES_RETURN]: 'Si, regresar',
      [TRANSLATIONS.RETURNED_SIO]: 'ACI regresado',
      [TRANSLATIONS.THE_SIO_WAS_RETURNED_TO_BE_REASSIGNED]: 'El ACI fue regresado para ser asignado nuevamente.',
      [TRANSLATIONS.ACT_CLOSED_SUCCESSFULLY]: '¡Acto cerrado exitosamente!',
      [TRANSLATIONS.CLOSE_MESSAGE]: 'Cerrar mensaje',
      [TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED]: 'El acto fue registrado y cerrado de manera exitosa.',
      [TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_CLOSED]: 'La condición fue cerrada de manera exitosa.',
      [TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_CLOSED]: 'El acto fue cerrado de manera exitosa.',
      [TRANSLATIONS.DO_YOU_WANT_TO_CLOSE_THE_ACT_AT_THIS_TIME]: '¿Deseas cerrar el acto en este momento?',
      [TRANSLATIONS.EDIT_SIO]: 'Editar ACI',
      [TRANSLATIONS.POSTPONE_SIO]: 'Posponer ACI',
      [TRANSLATIONS.POSTPONE_CONDITION]: 'Posponer condición',
      [TRANSLATIONS.POSTPONE_ACT]: 'Posponer acto',
      [TRANSLATIONS.POSTPONE]: 'Posponer',
      [TRANSLATIONS.CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_CONDITION]: 'Elige una fecha para cuando cerrarás esta condición',
      [TRANSLATIONS.CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_ACT]: 'Elige una fecha para cuando cerrarás este acto',
      [TRANSLATIONS.CONDITION_POSTPONED_SUCCESSFULLY]: '¡Condición pospuesta exitosamente!',
      [TRANSLATIONS.ACT_POSTPONED_SUCCESSFULLY]: '¡Acto pospuesto exitosamente!',
      [TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_POSTPONED]: 'La condición fue pospuesta de manera exitosa.',
      [TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_POSTPONED]: 'El acto fue pospuesto de manera exitosa.',
      [TRANSLATIONS.OK]: 'Ok',
      [TRANSLATIONS.UPLOAD_EVIDENCE_OF_ACI_CREATION]: 'Subir evidencia de creación de ACI',
      [TRANSLATIONS.UPLOAD_EVIDENCE_TO_CLOSE_ACI]: 'Subir evidencia para cerrar ACI',
      [TRANSLATIONS.SIF]: 'SIF',
      [TRANSLATIONS.RETURNED_BY]: 'Devuelto por',
      [TRANSLATIONS.RETURN_CAUSE]: 'Causa de devolución',
      [TRANSLATIONS.OPENING_OF_QUESTIONS]: 'Apertura de preguntas',
      [TRANSLATIONS.FORBIDDEN_MISSING_PERMISSIONS]: 'Prohibido: Falta de Permisos',
      [TRANSLATIONS.START_WORKING_DAY]: 'Fecha de contratación',
      [TRANSLATIONS.POWER_BI_SIOS_T2]: 'Power BI ACIs T2',
      [TRANSLATIONS.DETAILS]: 'Detalles',
      [TRANSLATIONS.START_MONTH]: 'Mes de inicio',
      [TRANSLATIONS.END_MONTH]: 'Mes de fin',
      [TRANSLATIONS.REPORTED_BY]: 'Reportado por',
      [TRANSLATIONS.ASSIGNED_TO]: 'Asignado a',
      [TRANSLATIONS.THE_GOLDEN_RULE_IDENTIFIES_RISKS_WHEN_BREAKING_THE_RULES_PROVIDING_AN_ADDITIONAL_LAYER_OF_SECURITY_IF_YOU_FAIL_TO_COMPLY_THE_USER_WILL_BE_ASKED_FOR_EVIDENCE_WHEN_CLOSING_THE_ACI]: 'La regla de oro identifica los riesgos al romper las reglas, proporcionando una capa adicional de seguridad. De incumplirla, se pedirá al usuario evidencia al cerrar el ACI.',
      [TRANSLATIONS.SIF_BEING_SERIOUS_INJURIES_AND_FATALITIES_WHICH_TRANSLATES_TO_SERIOUS_INJURIES_AND_FATALITIES]: 'SIF siendo Serious injuries and fatalities, lo cual se traduce a Lesiones graves y víctimas mortales',
      [TRANSLATIONS.IS_IT_AN_ACI_TYPE_SIF]: '¿Es un ACI tipo SIF?',
      [TRANSLATIONS.YOU_HAVE_REACH_THE_LIMIT_OF_REPORTS_PLEASE_TRY_AGAIN_AT]: 'Has alcanzado el límite de REPORTES, por favor intenta a las {{date}}',
      [TRANSLATIONS.FOLLOW_THE_GOLDEN_RULE]: 'Cumple la regla de oro',
      [TRANSLATIONS.BREAKS_THE_GOLDEN_RULE]: 'Incumple la regla de oro',
      [TRANSLATIONS.ACI_CONSIDERED]: 'ACI Considerado',
      [TRANSLATIONS.SERIOUS_INJURIES_AND_FATALITIES]: 'Lesiones graves y víctimas mortales',
      [TRANSLATIONS.LIST_OF_THE_USERS_THAT_ARE_ASSIGNED_TO_THE_SELECTED_LOCATION]: 'Lista de los usuarios que trabajan en las ubicaciones seleccionadas',
      [TRANSLATIONS.LIST_OF_THE_USERS_THAT_HAVE_ACCESS_TO_THE_SELECTED_LOCATION_USERS_MAY_APPEAR_MORE_THAN_ONCE_IN_DIFFERENT_LOCATIONS]: 'Lista de los usuarios que tienen acceso a las ubicaciones seleccionadas. Los usuarios pueden aparecer más de una vez en distintas ubicaciones.',
      [TRANSLATIONS.YOU_DONT_HAVE_ACCESS_TO_LOCATION_PLEASE_CONTACT_SUPERUSER]: 'Sin ubicaciones, contacta a un super usuario.',
      [TRANSLATIONS.DOWNLOAD_SKAP_HC_TITLE]: 'HC de SKAP',
      [TRANSLATIONS.DOWNLOAD_SKAP_HC_DESCRIPTION]: 'Lista de los usuarios que tienen una posición y los permisos que te dan SKAP y trabajan en las ubicaciones seleccionadas.',
      [TRANSLATIONS.SHOW_DELETED_USERS]: 'Mostrar usuarios eliminados',
      [TRANSLATIONS.FIND_USERS_DESCRIPTION]: 'Para buscar usuarios, utiliza SHARP o correo electrónico. Ingresa al menos 6 caracteres o letras en la barra de búsqueda ubicada en la parte superior de la pantalla.',
      [TRANSLATIONS.SEARCH_FOR_DELETED_USERS_BY_SHARP_EMAIL_QR_OR_PIN]: 'Buscar usuarios eliminados por SHARP, QR, pin o correo electrónico.',
      [TRANSLATIONS.REHABILITATE_USER]: 'Rehabilitar usuario',
      [TRANSLATIONS.GRADES_CURRENT_STATUS]: 'Estado Actual',
      [TRANSLATIONS.MISSING_DATA_TO_FILL]: 'Faltan datos por llenar',
      [TRANSLATIONS.WORKS_AT_FIELD_NEEDS_TO_BE_ADDED_TO_USER]: 'Falta agregar el campo “Trabaja en” al usuario.',
      [TRANSLATIONS.USER_NEEDS_TO_BE_ASSIGNED_A_POSITION]: 'Falta asignarle posición al usuario.',
      [TRANSLATIONS.USERS_HIRING_DATE_NEEDS_TO_BE_ADDED]: 'Falta agregar fecha de contratación al usuario.',
      [TRANSLATIONS.THIS_EMAIL_IS_ALREADY_REGISTER]: 'Este email ya se encuentra registrado',
      [TRANSLATIONS.EDIT_OPERATIONS]: 'Configuración areas/subareas',
      [TRANSLATIONS.ERROR_LOADING_INFORMATION]: 'Error cargando la información',
      [TRANSLATIONS.EDIT_OPERATION_LOCATION_NOT_SUPPORTED]: 'Esta ubicación es un CD por lo tanto no puede ser editada',
      [TRANSLATIONS.SUPER_USER_DOESNT_HAVE_ACCESS_TO_LOCATION]: 'No tienes acceso a esta ubicación',
      [TRANSLATIONS.STRUCTURE_TREE]: 'Estructura',
      [TRANSLATIONS.RELOAD_DATA]: 'Volver a cargar información',
      [TRANSLATIONS.DEVIATIONS]: 'Desviaciones',
      [TRANSLATIONS.USABILITY_DPO]: 'Usabilidad DPO',
      [TRANSLATIONS.USABILITY_DPO_HEADING]: 'Usabilidad DPO 2024',
      [TRANSLATIONS.RULES]: 'Reglas',
      [TRANSLATIONS.ACTION_PLANS]: 'Planes de Acción',
      [TRANSLATIONS.ZOOM_ROUTINES]: 'Zoom Rutinas',
      [TRANSLATIONS.FIVE_S]: '5s',
      [TRANSLATIONS.SCORECARD]: 'Scorecard',
      [TRANSLATIONS.TEAM_ROOMS]: 'TeamRooms',
      [TRANSLATIONS.WORKSTATION]: 'Workstation',
      [TRANSLATIONS.PLASSE_USE_SINGAL_SIGN_ON_TO_ACCESS]: 'Por favor use iniciar sesión para acceder al sistema',
      [TRANSLATIONS.FINANZAS]: 'Finanzas',
      [TRANSLATIONS.COMERCIAL]: 'Comercial',
      [TRANSLATIONS.SERVICIOS]: 'Servicios',
      [TRANSLATIONS.ROAD_SAFETY]: 'Road Safety',
      [TRANSLATIONS.ROAD_SAFETY_PLANNING]: 'Planeación',
      [TRANSLATIONS.CRITICAL_PATHS]: 'Rutas críticas',
      [TRANSLATIONS.DRIVERS]: 'Choferes',
      [TRANSLATIONS.RESOURCES]: 'Recursos',
      [TRANSLATIONS.TRUCK_ARCHIVE]: 'Archivo de matriz de camiones',
      [TRANSLATIONS.UPLOAD_NEW_TRUCK_FILE]: 'Cargar nuevo archivo de matriz de camiones',
      [TRANSLATIONS.DELIVERER_TRUCK_REQUIREMENTS_PDF]: 'Requisitos Entregador-Camión (PDF)',
      [TRANSLATIONS.RISK_PATH_FLOW_PDF]: 'Flujo Ruta de Riesgo (PDF)',
      [TRANSLATIONS.INFORMATION_RESOURCES]: 'Recursos informativos',
      [TRANSLATIONS.LOADING_ERROR_PLEASE_RETRY]: 'Error en la carga. Por favor reintenta.',
      [TRANSLATIONS.UPLOAD_ANOTHER_FILE]: 'Cargar otro archivo',
      [TRANSLATIONS.UPLOAD_NEW_DRIVER_FILE]: 'Cargar nuevo archivo de choferes',
      [TRANSLATIONS.NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_DRIVER_FILE]: 'No hay datos cargados aún. Presiona ”Cargar nuevo archivo de choferes”.',
      [TRANSLATIONS.SELECT_A_DISTRIBUTION_CENTER]: 'Selecciona un Centro de Distribución',
      [TRANSLATIONS.SELECT_A_BUSINESS_UNIT]: 'Selecciona una BU',
      [TRANSLATIONS.IN_ORDER_TO_ACCESS_THE_ROAD_SAFETY_SETTINGS_IT_IS_NECESSARY_TO_SELECT_A_LOCATION_USING_THE_TOP_BAR]: 'Para acceder a esta página es necesario seleccionar un Centro de Distribución en la parte superior de la página.',
      [TRANSLATIONS.IN_ORDER_TO_ACCESS_DAILY_AGENDA_IT_IS_NECESSARY_TO_SELECT_A_BUSINESS_UNIT_USING_THE_TOP_BAR]: 'Para acceder a esta página es necesario seleccionar una BU en la parte superior de la página.',
      [TRANSLATIONS.UPLOADING_FILE]: 'Cargando Archivo',
      [TRANSLATIONS.THE_FOLLOWING_USERS_HAD_A_PROBLEM_LOADING_IT_MAY_BE_THAT_THEY_DO_NOT_EXIST_IN_THE_SYSTEM_BASE_OR_THAT_THEIR_QR_IS_INCORRECT]: 'Los siguientes usuarios tuvieron problema en la carga. Puede ser que no existan en la base del sistema o que su QR esté incorrecto.',
      [TRANSLATIONS.PROBLEM_WITH_USERS]: 'Problema con usuario(s)',
      [TRANSLATIONS.SOME_OF_THE_USERS_DO_NOT_EXIST_IN_THE_SAFETY_DATABASE_REQUEST_THEIR_REGISTRATION_BEFORE_UPLOADING_THEM_HERE]: 'Algunos de los usuarios no existen en la base de datos de Safety. Solicita su alta antes de cargarlos aquí.',
      [TRANSLATIONS.LAST_UPDATED_ON_RS]: 'Actualizado por última vez el',
      [TRANSLATIONS.YOU_ARE_ABOUT_TO_CLOSE_AN_INSECURE_ACT_THAT_IS_A_GOLDEN_RULE]: 'Estas por cerrar un acto inseguro que es regla de oro',
      [TRANSLATIONS.HAS_THE_CORRESPONDING_CONSEQUENCE_FLOW_BEEN_APPLIED]: '¿Se ha aplicado el flujo de consecuencias correspondiente?',
      [TRANSLATIONS.UPLOAD_NEW_ROUTE_FILE]: 'Cargar nueva actualización de rutas críticas',
      [TRANSLATIONS.UPLOAD_ROAD_SAFETY_CHECKLIST_STRUCTURE]: 'Actualizar estructura de checklists Road Safety',
      [TRANSLATIONS.NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_CRITICAL_PATH_FILE]: 'No hay datos cargados aún. Presiona ”Cargar nuevo archivo de rutas críticas”.',
      [TRANSLATIONS.RETURN_TO_CRITICAL_PATHS]: 'Regresar a Rutas críticas',
      [TRANSLATIONS.ROUTE]: 'Ruta',
      [TRANSLATIONS.ROUTE_DATA]: 'Datos de ruta',
      [TRANSLATIONS.GENERAL_INFORMATION]: 'Info general',
      [TRANSLATIONS.ROUTE_ID]: 'ID Ruta',
      [TRANSLATIONS.LAST_UPDATE]: 'Últ. actualización',
      [TRANSLATIONS.LAST_DRIVER]: 'Últ. Chofer',
      [TRANSLATIONS.DESTINATION]: 'Destino',
      [TRANSLATIONS.SEE_ROUTE]: 'Ver Ruta',
      [TRANSLATIONS.DRIVER_SUCCESSFULLY_REMOVED]: 'Conductor eliminado exitosamente',
      [TRANSLATIONS.WITHOUT_CODE]: 'Sin código',
      [TRANSLATIONS.LAST_TRUCK]: 'Último camión',
      [TRANSLATIONS.DRIVER]: 'Chofer',
      [TRANSLATIONS.TRUCK]: 'Camión',
      [TRANSLATIONS.EVALUATOR]: 'Evaluador',
      [TRANSLATIONS.FIND_ANOTHER_ROUTE]: 'Buscar otra ruta',
      [TRANSLATIONS.REMOVE]: 'Quitar a',
      [TRANSLATIONS.FROM_THE_ROUTE]: 'de la ruta',
      [TRANSLATIONS.DRIVERS_SUITABLE_FOR_THE_SELECTED_ROUTE]: 'Choferes aptos para la ruta seleccionada',
      [TRANSLATIONS.APPLY_CHANGE]: 'Aplicar cambio',
      [TRANSLATIONS.TRUCKS_SUITABLE_FOR_SELECTED_ROUTES]: 'Camiones aptos para la ruta seleccionada',
      [TRANSLATIONS.SEARCH_TRUCK]: 'Buscar camión',
      [TRANSLATIONS.PLANNING_FOR_TODAY]: 'Planeación de hoy',
      [TRANSLATIONS.SELECT_RS]: 'Seleccionar',
      [TRANSLATIONS.SELECT_DRIVER]: 'Seleccionar chofer',
      [TRANSLATIONS.ADD_DRIVER]: 'Agregar chofer',
      [TRANSLATIONS.AT_RISK]: 'En atención',
      [TRANSLATIONS.CRITICAL]: 'Crítica',
      [TRANSLATIONS.RELIEF_DRIVER]: 'Chofer Relevo',
      [TRANSLATIONS.TIME_FOR_CHANGE]: 'Hora de cambio',
      [TRANSLATIONS.DRIVER_WITHOUR_ROUTE]: 'Chofer sin ruta',
      [TRANSLATIONS.CHANGE_DRIVER]: 'Cambiar chofer',
      [TRANSLATIONS.CHANGE_TRUCK]: 'Cambiar camión',
      [TRANSLATIONS.THERE_ARE_NO_OPTIONS_AVAILABLE_CANNOT_GO_ON_ROUTE]: 'No hay opciones disponibles. NO puede salir a ruta',
      [TRANSLATIONS.DUPLICATE_ON_ROTUE]: 'Duplicado en ruta',
      [TRANSLATIONS.LACK_OF_DRIVER_CANNOT_GO_ON_ROUTE]: 'Falta de chofer. NO puede salir a ruta',
      [TRANSLATIONS.SEARCH_DRIVER]: 'Buscar chofer',
      [TRANSLATIONS.ROUTE_SUCCESSFULLY_UPDATED]: 'Ruta actualizada con éxito',
      [TRANSLATIONS.PLEASE_SELECT_RELY_TIME_FOR_THIS_TRIP]: 'Por favor, seleccionar hora de cambio de conductor',
      [TRANSLATIONS.DRIVER_MATRIX]: 'Matriz de choferes',
      [TRANSLATIONS.CREATE_USER]: 'Crear usuario',
      [TRANSLATIONS.LOCATION_SELECT]: 'Selecciona la ubicación',
      [TRANSLATIONS.CREATE_BY_PIN]: 'Crear por PIN',
      [TRANSLATIONS.CREATE_BY_SHARP]: 'Crear por SHARP',
      [TRANSLATIONS.USER_INFORMATION]: 'Información del usuario',
      [TRANSLATIONS.FULL_NAME]: 'Nombre completo',
      [TRANSLATIONS.ENTER_USERS_FULL_NAME]: 'Ingresa el nombre completo del usuario',
      [TRANSLATIONS.SAP]: 'SAP/SHARP',
      [TRANSLATIONS.ENTER_USERS_SAP]: 'Ingresa el SAP/SHARP del usuario',
      [TRANSLATIONS.SELECT_USERS_POSITION]: 'Selecciona el puesto del usuario',
      [TRANSLATIONS.SELECT_HIRING_DATE]: 'Selecciona la fecha de contratación',
      [TRANSLATIONS.USER_PERMISSIONS]: 'Permisos del usuario',
      [TRANSLATIONS.SAP_MUST_CONTAIN_8_DIGITS]: 'El SAP/SHARP debe contener 8 dígitos.',
      [TRANSLATIONS.SAP_SHARP_AVAILABLE]: 'SAP/SHARP disponible.',
      [TRANSLATIONS.SAP_SHARP_ALREADY_REGISTERED]: 'SAP/SHARP ya registrado.',
      [TRANSLATIONS.USER_CREATED_SUCCESSFULLY]: 'Usuario creado exitosamente',
      [TRANSLATIONS.SAP_SHOULD_ONLY_CONTAIN_NUMBERS]: 'El SAP/SHARP debe de tener solo números.',
      [TRANSLATIONS.PLEASE_ENTER_8_CHARACTERS]: 'Por favor ingrese al menos 8 caracteres',
      [TRANSLATIONS.SPECIAL_CHARACTERS_NOT_ALLOWED]: 'Caracteres especiales no permitidos',
      [TRANSLATIONS.WRITE_HERE_THE_DESCRIPTION_OF_THE_CONSEQUENCE_APPLIED]: 'Escribír aquí la descripción de la consecuencia aplicada',
      [TRANSLATIONS.PATIO_DRIVER]: 'Chofer de Patio',
      [TRANSLATIONS.EDIT_USER_WITHOUT_ROLE]: 'El usuario no tiene un rol, si guardas los cambios sin seleccionar un rol se asignara el rol: Certificates Only',
      [TRANSLATIONS.ROLE_AND_LOCATION_TYPE_MISMATCH]: 'El rol asignado no tiene el mismo tipo de la operación asignada, toma esto en consideración antes de guardar',
      [TRANSLATIONS.AUTOMATIC_ASSIGNMENT_OF_ACIS]: 'Asignación automática de ACIs',
      [TRANSLATIONS.ALLOW_AUTOMATIC_ASSIGNMENT_OF_ACIS]: "Permitir asignación automática de ACI's",
      [TRANSLATIONS.PLEASE_SELECT_AREA]: 'Por favor Seleccionar área',
      [TRANSLATIONS.PLEASE_SELECT_SUBAREA]: 'Por favor Seleccionar subárea',
      [TRANSLATIONS.SELECT_LOCATIONS_FOR_AUTO_ASSIGN_SIOS_IN_PERMISSION_TAB]: "Elige ubicación para la asignación automática de ACI's en la pestaña de permisos.",
      [TRANSLATIONS.SEARCH_FOR_OWD_HERE]: 'Busca por OWD aquí',
      [TRANSLATIONS.BLOCKED_USER_SHOULD_CONTACT_A_SUPERUSER_FOR_MORE_INFORMATION]: 'Usuario bloqueado debe comunicarse con un super usuario para obtener más información.',
      [TRANSLATIONS.CHECKLIST_DELETED_SUCCESSFULLY]: 'Checklist eliminado correctamente',
      [TRANSLATIONS.CHECKLIST_EDITED_CORRECTLY]: 'Checklist editado correctamente',
      [TRANSLATIONS.ERROR_PLEASE_RETRY]: 'Error, favor de reintentar',
      [TRANSLATIONS.NO_DATA_FOUND_FOR_THIS_SECTION_SO_FAR]: 'Hasta el momento no se encontraron datos para esta sección',
      [TRANSLATIONS.ENTER_YOUR_NAME]: 'Ingresa tu nombre',
      [TRANSLATIONS.ENTER_YOUR_FIRST_SURNAME]: 'Ingresa tu primer apellido',
      [TRANSLATIONS.ENTER_YOUR_SECOND_SURNAME]: 'Ingresa tu segundo apellido',
      [TRANSLATIONS.ENTER_YOUR_EMAIL]: 'Ingresa tu correo',
      [TRANSLATIONS.DATE_MUST_BE_WITHIN_THE_PAST_100_YEARS]: 'La fecha debe estar dentro de los últimos 100 años.',
      [TRANSLATIONS.USE_SSO_TO_LOGIN]: 'Utilize SSO para entrar',
      [TRANSLATIONS.DAILY_AGENDA]: 'Agenda digital',
      [TRANSLATIONS.SCHEDULE]: 'Programación',
      [TRANSLATIONS.ACTIVITIES]: 'Actividades',
      [TRANSLATIONS.TOTAL_MONTH]: 'Total mes',
      [TRANSLATIONS.EDIT_SCHEDULE]: 'Editar programación',
      [TRANSLATIONS.DRAG_A_CREATED_TASK_TO_START]: 'Arrastra una tarea creada para comenzar',
      [TRANSLATIONS.DELETE_POSITION]: 'Borrar posición',
      [TRANSLATIONS.THERE_ARE_NO_POSITIONS_CREATED_YET_TO_ADD_A_NEW_ONE_PRESS_ADD_POSITION]: 'No hay posiciones creadas aún, para agregar una nueva presiona ”+ Agregar posición”.',
      [TRANSLATIONS.THRE_ARE_NO_TASKS_CREATED_YET_TO_CREATE_A_NEW_ONE_PRESS_NEW]: 'Todavía no hay tareas creadas, para crear una nueva presiona ”+ Nueva”.',
      [TRANSLATIONS.THE_TASKS_OF_THE_DPO_MODULES_ARE_ALREADY_CONNECTD_TO_THE_DAILY_AGENDA_THEY_DO_NOT_NEED_TO_BE_CREATED]: 'Las tareas de los módulos de DPO ya están conectadas a Agenda Digital, no son necesarias ser creadas.',
      [TRANSLATIONS.NEW]: 'Nueva',
      [TRANSLATIONS.ADD_POSITION]: 'Agregar posición',
      [TRANSLATIONS.POSITIONS_OF]: 'Posiciones de',
      [TRANSLATIONS.ACCUMULATIVE]: 'Acumultiva',
      [TRANSLATIONS.DAILY]: 'Diaria',
      [TRANSLATIONS.WEEKLY]: 'Semanal',
      [TRANSLATIONS.BIWEEKLY]: 'Quincenal',
      [TRANSLATIONS.MONTHLY]: 'Mensual',
      [TRANSLATIONS.QUARTERLY]: 'Trimestral',
      [TRANSLATIONS.NEW_ACTIVITY]: 'Nueva actividad',
      [TRANSLATIONS.WRITE_ACTIVITY_NAME]: 'Escribe nombre de actividad',
      [TRANSLATIONS.WRITE_ENGLISH_ACTIVITY_NAME]: 'Escribe nombre en ingles de la actividad',
      [TRANSLATIONS.WRITE_DESCRIPTION]: 'Escribe descripción',
      [TRANSLATIONS.THIS_ACTIVITY_WILL_ONLY_DISPLAY_ONE_QUESTION_IN_THE_MOBILE_APP_WHICH_WILL_BE_TO_REQUEST_PHOTOGRAPHIC_EVIDENCE]: 'Esta actividad mostrará únicamente una pregunta en la aplicación móvil, misma que será para pedir una evidencia fotográfica.',
      [TRANSLATIONS.SELECT_PILLAR]: 'Selecciona pilar',
      [TRANSLATIONS.CREATE]: 'Crear',
      [TRANSLATIONS.TO_EDIT_ANOTHER_ONE_GO_BACK_AND_SELECT_A_DIFFERENT_BU]: 'Para editar otra regresa y selecciona una BU diferente.',
      [TRANSLATIONS.PROGRAMMING_OF]: 'Programación de',
      [TRANSLATIONS.EDITING_AGENDA_PROGRAMMING]: 'Editando programación agenda',
      [TRANSLATIONS.SAVE_CHANGES]: 'Guardar cambios',
      [TRANSLATIONS.PROGRAMMING_AGENDA]: 'Programación agenda',
      [TRANSLATIONS.WATCHING_PROGRAMMING_OF]: 'Viendo programación de',
      [TRANSLATIONS.THERE_IS_NO_SCHEDULE_YET_TO_START_PRESS_EDIT_SCHEDULE]: 'No hay programación aún, para comenzar presiona ”Editar programación”.',
      [TRANSLATIONS.THERE_ARE_N0_SCHEDULES_RECORDED_FOR_THE_SELECTED_BU_YET_START_BY_PRESSING_THE_EDIT_SCHEDULE_BUTTON_AT_THE_TOP_RIGHT_TO_BEGIN_ADDING_TASKS_TO_THE_DIFFERENT_POSITIONS]: 'Todavía no hay ninguna programación registrada para la BU seleccionada, comienza presionando el botón de Editar programación arriba a la derecha para comenzar a agregar tareas a las distintas posiciones.',
      [TRANSLATIONS.PROGRAMMING_EFFECTIVE_FROM]: 'Programación vigente desde el',
      [TRANSLATIONS.THESE_CHANGES_WILL_TAKE_EFFECT_UNTIL]: 'Estos cambios surtirán efecto hasta el',
      [TRANSLATIONS.SELECT_THE_BU_AND_PERIOD_TO_DOWNLOAD_THE_DATA]: 'Selecciona las ubicaciones y periodo que desees para descargar los datos',
      [TRANSLATIONS.DOWNLOADING_PROGRAMMING_OF]: 'Descargando programacion de',
      [TRANSLATIONS.THE_QUARTERLY_ACTIVITIES_DO_NOT_COUNT_FOR_THE_MONTHLY_SUMS_OF_THE_USERS]: 'Las actividades trimestrales no contarán para las sumas mensuales de los usuarios.',
      [TRANSLATIONS.THESE_ACTIVITIES_HAVE_INDIVIDUAL_DUE_DATES]: 'Estas actividades tienen vencimiento individual.',
      [TRANSLATIONS.YOU_HAVE_UNTIL]: 'Tienes hasta',
      [TRANSLATIONS.QUARTERLY_PROGRESS]: 'progreso trimestral',
      [TRANSLATIONS.MONTHLY_PROGRESS]: 'progreso mensual',
    },
  },
  en: {
    translation: {
      [TRANSLATIONS.APPLY_OWDS]: 'Apply OWDs',
      [TRANSLATIONS.LOADING_FILE]: 'Loading file',
      [TRANSLATIONS.SELECT_DISTRIBUTION_CENTER]: 'Select a Distribution Center',
      [TRANSLATIONS.SELECT_DISTRIBUTION_PARAGRAPH]: 'In order to access the configuration of the OWDs it is necessary to select a Distribution Center using the top bar',
      [TRANSLATIONS.SELECT_DISTRIBUTION_5S_PARAGRAPH]: 'In order to access the 5s configuration it is necessary to select a Distribution Center using the top bar.',
      [TRANSLATIONS.LAST_LOAD]: 'Last load',
      [TRANSLATIONS.TEMPLATE]: 'Template',
      [TRANSLATIONS.DOWNLOAD_FILE]: 'Download file',
      [TRANSLATIONS.DOWNLOAD_TEMPLATE]: 'Download Template',
      [TRANSLATIONS.OVER_WRITE_FILE]: 'Overwrite File',
      [TRANSLATIONS.ARCHIVO]: 'Archive',
      [TRANSLATIONS.WITHIN_24_HOURS]: 'Less than 24 hours',
      [TRANSLATIONS.MORE_24_HOURS]: 'More than 24 hours',
      [TRANSLATIONS.week1]: 'week 1',
      [TRANSLATIONS.week2]: 'week 2',
      [TRANSLATIONS.week3]: 'week 3',
      [TRANSLATIONS.week4]: 'week 4',
      [TRANSLATIONS.week5]: 'week 5',
      [TRANSLATIONS.week6]: 'week 6',
      [TRANSLATIONS.week7]: 'week 7',
      [TRANSLATIONS.week8]: 'week 8',
      [TRANSLATIONS.week9]: 'week 9',
      [TRANSLATIONS.week10]: 'week 10',
      [TRANSLATIONS.week11]: 'week 11',
      [TRANSLATIONS.week12]: 'week 12',
      [TRANSLATIONS.week13]: 'week 13',
      [TRANSLATIONS.week14]: 'week 14',
      [TRANSLATIONS.week15]: 'week 15',
      [TRANSLATIONS.week16]: 'week 16',
      [TRANSLATIONS.week17]: 'week 17',
      [TRANSLATIONS.week18]: 'week 18',
      [TRANSLATIONS.week19]: 'week 19',
      [TRANSLATIONS.week20]: 'week 20',
      [TRANSLATIONS.week21]: 'week 21',
      [TRANSLATIONS.week22]: 'week 22',
      [TRANSLATIONS.week23]: 'week 23',
      [TRANSLATIONS.week24]: 'week 24',
      [TRANSLATIONS.week25]: 'week 25',
      [TRANSLATIONS.week26]: 'week 26',
      [TRANSLATIONS.week27]: 'week 27',
      [TRANSLATIONS.week28]: 'week 28',
      [TRANSLATIONS.week29]: 'week 29',
      [TRANSLATIONS.week30]: 'week 30',
      [TRANSLATIONS.week31]: 'week 31',
      [TRANSLATIONS.week32]: 'week 32',
      [TRANSLATIONS.week33]: 'week 33',
      [TRANSLATIONS.week34]: 'week 34',
      [TRANSLATIONS.week35]: 'week 35',
      [TRANSLATIONS.week36]: 'week 36',
      [TRANSLATIONS.week37]: 'week 37',
      [TRANSLATIONS.week38]: 'week 38',
      [TRANSLATIONS.week39]: 'week 39',
      [TRANSLATIONS.week40]: 'week 40',
      [TRANSLATIONS.week41]: 'week 41',
      [TRANSLATIONS.week42]: 'week 42',
      [TRANSLATIONS.week43]: 'week 43',
      [TRANSLATIONS.week44]: 'week 44',
      [TRANSLATIONS.week45]: 'week 45',
      [TRANSLATIONS.week46]: 'week 46',
      [TRANSLATIONS.week47]: 'week 47',
      [TRANSLATIONS.week48]: 'week 48',
      [TRANSLATIONS.week49]: 'week 49',
      [TRANSLATIONS.week50]: 'week 50',
      [TRANSLATIONS.week51]: 'week 51',
      [TRANSLATIONS.week52]: 'week 52',
      [TRANSLATIONS.week53]: 'week 53',
      [TRANSLATIONS.week54]: 'week 54',
      [TRANSLATIONS.REFERNCIA]: 'Refernce',
      [TRANSLATIONS.DATE_INCIDENT]: 'Date Incident',
      [TRANSLATIONS.VALIDITY]: 'Validity',
      [TRANSLATIONS.NATURE]: 'Nature',
      [TRANSLATIONS.CAUSE]: 'Cause',
      [TRANSLATIONS.SUB_CAUSE]: 'Sub Cause',
      [TRANSLATIONS.WELCOME_TO]: 'Welcome to',
      [TRANSLATIONS.CREATE_YOUR_PASSWORD_TO_ACCESS]: 'Create your password to access',
      [TRANSLATIONS.FORGOT_YOUR_PASSWORD]: 'Forgot your password?',
      [TRANSLATIONS.I_REMEMBER_MY_PASSWORD]: 'I remember my password',
      [TRANSLATIONS.RECOVER_IT_HERE]: 'Click here',
      [TRANSLATIONS.LETS_FIND_YOUR_ACC]: 'Lets find your account',
      [TRANSLATIONS.TYPE_YOUR_EMAIL]: 'Type your email used for Safety 360',
      [TRANSLATIONS.SEND_ME_THE_LINK]: 'Email me recovery link',
      [TRANSLATIONS.EMAIL_SENT]: 'Check your email!',
      [TRANSLATIONS.YOU_WILL_RECEIVE_AN_EMAIL]: 'You will receive a link at',
      [TRANSLATIONS.TO_RESET_PASSWORD]: 'to reset your password',
      [TRANSLATIONS.PASSWORD]: 'Password',
      [TRANSLATIONS.CONFIRM_PASSWORD]: 'Confirm password',
      [TRANSLATIONS.ALREADY_HAVE_AN_ACCOUNT]: 'Already have an account?',
      [TRANSLATIONS.LOGIN]: 'Login',
      [TRANSLATIONS.GO_TO_LOGIN]: 'Go to login',
      [TRANSLATIONS.EMAIL]: 'Email',
      [TRANSLATIONS.LOADING]: 'Loading',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED]: 'Your password was successfully created.',
      [TRANSLATIONS.YOU_CAN_START_USING_THE_APP]: 'You can start using the App!',
      [TRANSLATIONS.OPEN_APP]: 'Open app',
      [TRANSLATIONS.CONTACT]: 'Contact',
      [TRANSLATIONS.THIS_URL_HAS_EXPIRED]: 'This URL has expired',
      [TRANSLATIONS.LOGIN_TO_ACCESS_SAFETY_DASHBOARD]: 'Login to access Safety 360 dashboard',
      [TRANSLATIONS.LOGGING_IN]: 'Logging in',
      [TRANSLATIONS.NAME]: 'Name',
      [TRANSLATIONS.FIRST_SURNAME]: 'First surname',
      [TRANSLATIONS.SECOND_SURNAME]: 'Second surname',
      [TRANSLATIONS.OPTIONAL]: 'Optional',
      [TRANSLATIONS.REGISTER]: 'Register',
      [TRANSLATIONS.BLOCKED_REASON]: 'Blocked reason',
      [TRANSLATIONS.SEARCH]: 'Search',
      [TRANSLATIONS.BLOCK]: 'Block',
      [TRANSLATIONS.UNBLOCK]: 'Unblock',
      [TRANSLATIONS.CD_SETTINGS]: '{{ location }} Settings',
      [TRANSLATIONS.BACK_TO]: 'Back to',
      [TRANSLATIONS.SETTINGS]: 'Settings',
      [TRANSLATIONS.BLOCK_UNBLOCK]: 'Block/Unblock',
      [TRANSLATIONS.EXIT]: 'Exit',
      [TRANSLATIONS.FILE_UPLOAD]: 'File Upload',
      [TRANSLATIONS.WELCOME]: 'Welcome',
      [TRANSLATIONS.YOU_ARE_VIEWING]: 'You are viewing',
      [BLOCKED_REASONS.SELF_ASSESSMENT]: 'Self-assessment',
      [BLOCKED_REASONS.MANUAL]: 'Manual',
      [BLOCKED_REASONS.VISIT_DOCTOR]: 'Bloqueo Medico',
      [TRANSLATIONS.REPORTS]: 'Reports',
      [TRANSLATIONS.TOTAL_REPORTS]: 'Total SIOs',
      [TRANSLATIONS.UNIQUE]: 'Unique',
      [TRANSLATIONS.UNIQUE_USERS]: 'Unique users',
      [TRANSLATIONS.AVERAGE_PER_USER]: 'Avg. per user',
      [TRANSLATIONS.AVERAGE_DAILY_PER_USER]: 'Daily avg. per user',
      [TRANSLATIONS.LAST_7_DAYS]: 'Last 7 days',
      [TRANSLATIONS.LAST_30_DAYS]: 'Last 30 days',
      [TRANSLATIONS.LAST_90_DAYS]: 'Last 90 days',
      [TRANSLATIONS.MONTH_TO_DATE]: 'Current month',
      [TRANSLATIONS.YEAR_TO_DATE]: 'Current year',
      [TRANSLATIONS.PRECIDING_PERIOD]: 'Preceding period',
      [TRANSLATIONS.REPORT_HISTORY]: 'Report history',
      [TRANSLATIONS.WHERE_ARE_YOU]: 'Where are you?',
      [TRANSLATIONS.ZONE]: 'Zone',
      [TRANSLATIONS.AGENCIA_DC]: 'DC',
      [TRANSLATIONS.CONTINUE]: 'Continue',
      [TRANSLATIONS.NEW_REPORT]: 'New report',
      [TRANSLATIONS.REPORT_DATA]: 'Report data',
      [TRANSLATIONS.WHO_IS_REPORTING_IT]: "Who's reporting it?",
      [TICKET_TYPE.CONDITION]: 'Condition',
      [SECURITY_STATUS.SECURE]: 'Safe',
      [SECURITY_STATUS.INSECURE]: 'Unsafe',
      [TRANSLATIONS.WHO_COMMITTED_THE_ACT]: 'Who committed the act *?',
      [TRANSLATIONS.IT_WAS_NOT_POSSIBLE_TO_IDENTIFY]: 'Non-identified user',
      [TRANSLATIONS.IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT]: 'It is going to be an anonymous report',
      [TRANSLATIONS.IT_IS_AN_ANONYMOUS_REPORT]: 'It is an anonymous report',
      [TRANSLATIONS.I_COULDNT_IDENTIFY_THE_PERSON]: 'Couldn\'t identify user',
      [TRANSLATIONS.SEARCH_THE_LIST_AGAIN]: 'Search the list again',
      [TRANSLATIONS.BACK_TO_SEARCH_BAR]: 'Back to search bar',
      [TRANSLATIONS.WHERE_DID_YOU_SEE]: 'Where did you see',
      [TRANSLATIONS.WHERE_DID_YOU_SEE_IN_DC_AREA]: 'Where did you see in CD area',
      [TRANSLATIONS.CD_AREA]: 'CD area *',
      [TRANSLATIONS.IN_WHICH_AREA_OF_DISTRIBUTION_CENTER]: 'In which area of the Distribution Center? *',
      [TRANSLATIONS.SPECIFY_THE_PLACE_IN_THE_SUBAREA]: 'Specify the place in the subarea',
      [TRANSLATIONS.TYPE_OF_RISK]: 'Risk type',
      [TRANSLATIONS.RISK_GROUP]: 'Clasification',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED]: 'What type of risk was prevented?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT]: 'What type of risk was prevented with this behavior?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION]: 'What type of risk was prevented with this condition?*',
      [TRANSLATIONS.WHO_HAD_THIS_BEHAVIOR]: 'Who had this behavior?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_BEHAVIOR]: 'Who reported this behavior?',
      [TRANSLATIONS.WHO_HAD_THIS_CONDITION]: 'Who had this condition?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_CONDITION]: 'Who reported this condition?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK]: 'Who reported this environmental risk?',
      [TRANSLATIONS.SELECT_RISK_TYPE]: 'Select type of risk',
      [TRANSLATIONS.SELECT_RISK_GROUP]: 'Select risk group',
      [TRANSLATIONS.BEHAVIOR]: 'Behavior',
      [TRANSLATIONS.DESCRIPTION]: 'Description',
      [TRANSLATIONS.OF_THE_ACT]: 'of the act',
      [TRANSLATIONS.OF_THE_CONDITION]: 'of the condition',
      [TRANSLATIONS.RISK_LEVEL_TRANSLATION]: 'Risk level',
      [RISK_LEVEL.HIGH]: 'High',
      [RISK_LEVEL.LOW]: 'Low',
      [PRIORITY.MEDIUM]: 'Medium',
      [TRANSLATIONS.CORRECTIVE_ACTION]: 'Corrective action',
      [TRANSLATIONS.UPLOAD_REGISTRATION]: 'Upload file',
      [TRANSLATIONS.UPLOAD_EVIDENCE]: 'Upload evidence',
      [TRANSLATIONS.ACI_OVERVIEW]: 'Summary',
      [TRANSLATIONS.LEVEL]: 'Level',
      [TRANSLATIONS.FINISH_AND_SEND]: 'Send',
      [TRANSLATIONS.THANK_YOU]: 'Thank You',
      [TRANSLATIONS.SENT_SUCCESSFULLY]: 'Sent successfully',
      [TRANSLATIONS.SIO_SUCCESSFULLY_SENT]: 'SIO sent successfully',
      [TRANSLATIONS.WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE]: 'With your help we can prevent more accidents in the future!',
      [TRANSLATIONS.THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS]: 'Thanks to you we can improve security among our collaborators.',
      [TRANSLATIONS.SUCCESSFUL_REGISTRATION]: 'Successful registration!',
      [TRANSLATIONS.YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY]: 'Your registration was send successfully',
      [TRANSLATIONS.THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT]: 'The report has been successfully sent',
      [TRANSLATIONS.DOWNLOAD]: 'Download',
      [TRANSLATIONS.CERTIFICATES]: 'certificates',
      [TRANSLATIONS.AMBIENTAL_RISKS]: 'Ambiental Risks',
      [TRANSLATIONS.BOTH]: 'both',
      [TRANSLATIONS.INCIDENTS]: 'SIOs',
      [TRANSLATIONS.INCIDENTS_PER_POSITION]: 'SIOs per position',
      [TRANSLATIONS.ALL_ZONES]: 'All zones',
      [TRANSLATIONS.DRVS]: 'DRVs',
      [TRANSLATIONS.DISTRIBUTION_CENTERS]: 'Location',
      [pluralize(TRANSLATIONS.DISTRIBUTION_CENTERS)]: 'Locations',
      [TRANSLATIONS.ACIS]: 'Reports',
      [TRANSLATIONS.LOGOUT]: 'Logout',
      [TRANSLATIONS.GO_TO_DASHBOARD]: 'Go to dashboard',
      [TRANSLATIONS.DECEMBER]: 'December',
      [TRANSLATIONS.NOVEMBER]: 'November',
      [TRANSLATIONS.OCTOBER]: 'October',
      [TRANSLATIONS.SEPTEMBER]: 'September',
      [TRANSLATIONS.AUGUST]: 'August',
      [TRANSLATIONS.JULY]: 'July',
      [TRANSLATIONS.JUNE]: 'June',
      [TRANSLATIONS.MAY]: 'May',
      [TRANSLATIONS.APRIL]: 'April',
      [TRANSLATIONS.MARCH]: 'March',
      [TRANSLATIONS.FEBRUARY]: 'February',
      [TRANSLATIONS.JANUARY]: 'January',
      [TRANSLATIONS.ALL_MONTHLY_OPTIONS]: 'All',
      // external users module - start
      [TRANSLATIONS.EXTERNAL]: 'External',
      [TRANSLATIONS.INTERNAL]: 'Internal',
      [TRANSLATIONS.SEARCH_USERS]: 'Search users',
      [TRANSLATIONS.ADD_USER_TO_DC]: 'Add user to',
      [TRANSLATIONS.USER_ALREADY_EXISTS]: 'Check if user already exists',
      [TRANSLATIONS.DOES_USER_ACCOUNT]: 'Does user already have a Safety360 account?',
      [TRANSLATIONS.YES_HE_HAS]: 'Yes, he/she has',
      [TRANSLATIONS.ENTER_EMAIL]: 'Enter email',
      [TRANSLATIONS.NO_CREATE_SCRATCH]: 'No, create user from scratch',
      [TRANSLATIONS.POSITION]: 'Position',
      [TRANSLATIONS.PERMISSIONS]: 'Permissions',
      [TRANSLATIONS.ROUTINES]: 'Routines',
      [TRANSLATIONS.TRAININGS]: 'Trainings',
      [TRANSLATIONS.ACCIDENTABILITY]: 'Accidentability',
      [TRANSLATIONS.ANALYTICS]: 'Analytics',
      [TRANSLATIONS.CANCEL]: 'Cancel',
      [TRANSLATIONS.ADD]: 'Add',
      [TRANSLATIONS.ACCESS]: 'Access',
      [TRANSLATIONS.INFORMATION_NOT_AVAILABLE]: 'Information not available',
      [TRANSLATIONS.EDIT_INFORMATION]: 'Edit',
      [TRANSLATIONS.DELETE_USER]: 'Delete User',
      [TRANSLATIONS.DELETE_USER_OPTION_MENU]: 'Delete',
      [TRANSLATIONS.CONFIRM]: 'Confirm',
      [TRANSLATIONS.ENTER_NAME]: 'Enter name',
      [TRANSLATIONS.YES]: 'Yes',
      [TRANSLATIONS.NO]: 'No',
      [TRANSLATIONS.USERS]: 'Users',
      [TRANSLATIONS.CHECK_YOUR_EMAIL]: 'Please check your email.',
      [TRANSLATIONS.ASK_USER_WHICH_EMAIL]: 'Ask the user what email he used to register for Safety360.',
      [TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER]: 'Something went wrong. Please try again later.',
      [TRANSLATIONS.USER_NOT_EXIST_CREATE_NEW]: 'User does not exist, verify the email or create a new user',
      [TRANSLATIONS.DELIVERY_MAN]: 'Delivery Driver',
      [TRANSLATIONS.SUPPLIER]: 'Supplier',
      [TRANSLATIONS.WAREHOUSE_ASSISTANT]: 'Warehouse Assistant',
      [TRANSLATIONS.PEOPLE_AND_MANAGEMENT]: 'People and Management',
      [TRANSLATIONS.FORKLIFT_DRIVER]: 'Forklift Driver',
      // external users module - end
      [TRANSLATIONS.WEEKLY_REPORTS]: 'Weekly Reports',
      [TRANSLATIONS.BOXES]: 'boxes',
      [TRANSLATIONS.WHO_COMMITTED]: 'Who did it',
      [TICKET_TYPE.ACT]: 'Act',
      [TRANSLATIONS.DATE_REPORTED]: 'Date Reported',
      [TRANSLATIONS.REPORTS_LIST]: 'SIOs List',
      [TRANSLATIONS.ALL]: 'All',
      [TRANSLATIONS.BEHAVIORS]: 'Behaviors',
      [TRANSLATIONS.CONDITIONS]: 'Conditions',
      [TRANSLATIONS.DOWNLOAD_LIST]: 'Download list',
      [TRANSLATIONS.PLACE]: 'Place',
      [TRANSLATIONS.TOTAL]: 'Total',
      [TRANSLATIONS.OPENED]: 'Opened',
      [TRANSLATIONS.CLOSED]: 'Closed',
      [TRANSLATIONS.ASSIGNED]: 'Assigned',
      [TRANSLATIONS.CLOSE]: 'Close',
      [TRANSLATIONS.REPORT]: 'Report',
      [TRANSLATIONS.ASSIGN]: 'Assign',
      [TRANSLATIONS.INSIDE_DC]: 'Inside Location',
      [TRANSLATIONS.OUTSIDE_DC]: 'Outside Location',
      [TRANSLATIONS.ENVIRONMENTAL_RISK]: 'Environmental risk',
      [pluralize(TRANSLATIONS.ENVIRONMENTAL_RISK)]: 'Environmental risks',
      [TRANSLATIONS.SAFETY_CERTIFICATES_T2]: 'Safety Certificates T2',
      [pluralize(TRANSLATIONS.ACT)]: 'Acts',
      [TRANSLATIONS.DESCRIPTION_OF_THE_CONDITION]: 'Describe the condition',
      [TRANSLATIONS.DESCRIBE_THE_BEHAVIOR]: 'Describe the behavior',
      [TRANSLATIONS.CONFIRM_USER_DELETION]: 'Are you sure you want to delete',
      [TRANSLATIONS.PIN_CREATED_SUCCESSFULLY]: 'PIN created successfully',
      [TRANSLATIONS.REGION]: 'Region',
      [TRANSLATIONS.LOCATION]: 'Location',
      [TRANSLATIONS.BUSINESS_UNIT]: 'Business Unit',
      [TRANSLATIONS.SELECT_REGION]: 'Select region',
      [TRANSLATIONS.SELECT_DC]: 'Select Location',
      [TRANSLATIONS.SELECT]: 'Select',
      [TRANSLATIONS.SELECT_SUBLOCATION]: 'Select Sublocation',
      [TRANSLATIONS.SELECT_POSITION]: 'Select a position',
      [TRANSLATIONS.WRITE_YOUR_8_DIGIT_SAP_NUMBER]: 'Type in your 8-digit #SAP',
      [TRANSLATIONS.WRITE_AN_8_DIGIT_SAP_NUMBER]: 'Type in an 8-digit #SAP',
      [TRANSLATIONS.SAP_SUCCESSFULLY_VALIDATED]: '#SAP successfully validated',
      [TRANSLATIONS.PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER]: ' Please make sure it\'s an 8-digit number.',
      [TRANSLATIONS.TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER]: 'To keep a track record of your reports, type in your SAP number.',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT]: 'If you leave it empty or incorrect this will count as anonymous report ☝🏼',
      [TRANSLATIONS.INCIDENTS_NATIONAL]: 'SIOs (National)',
      [TRANSLATIONS.CLEAR]: 'Clear',
      [TRANSLATIONS.OVERVIEW]: 'Overview',
      [TRANSLATIONS.DIST_CENTERS]: 'Locations',
      [TRANSLATIONS.HOME]: 'Home',
      [TRANSLATIONS.BLOCK_UNBLOCK_SHORT]: 'Block/Unblock',
      [TRANSLATIONS.PRECEDING_PERIOD]: 'Preceding period',
      [TRANSLATIONS.AVERAGE]: 'Average',
      [TRANSLATIONS.PARTICIPATION]: 'Participation',
      [TRANSLATIONS.DAILY_AVG_PER_USER]: 'Daily avg. per user',
      [TRANSLATIONS.ALL_THE_BUS]: 'All the BUs',
      [TRANSLATIONS.BU]: 'Country',
      [TRANSLATIONS.PASSWORD_TOO_SHORT]: 'Password too short',
      [TRANSLATIONS.PASSWORD_NO_MATCH]: 'Passwords do not match',
      [TRANSLATIONS.DO_NOT_USE_YOUR_OLD_PASSWORD]: 'Do not use your old password',
      [TRANSLATIONS.ROUTINES_FOLLOWUP]: 'Routines Follow-up',
      [TRANSLATIONS.WEEK]: 'Week',
      [TRANSLATIONS.TRAININGS_PERCENTAGE]: 'TRAININGS %',
      [TRANSLATIONS.SIF_ROUTE_PERCENTAGE]: 'SIF ROUTE %',
      [TRANSLATIONS.COURSES]: 'Courses',
      [TRANSLATIONS.HC_TO_BE_TRAINED]: 'HC to be trained',
      [TRANSLATIONS.TRAINED]: 'Trained',
      [TRANSLATIONS.IMAGE]: 'Image',
      [TRANSLATIONS.USER_ACCESS_DISCLAIMER]: 'If you don\'t see the correct locations, get in touch with a Super User. You can ask our support team for the Super User contact in the bottom-right corner.',
      [TRANSLATIONS.USER_MISSING_DISCLAIMER]: 'If internal users are missing, get in touch with our support team in the bottom-right corner.',
      [TRANSLATIONS.SECURE_CONDITION_DISCLAIMER]: 'The opened, closed and assigned sections, do not include secure SIOs',
      [TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT]: 'You don\'t have any location assigned, get in touch with a Super User. You can ask our support team for the Super User contact in the bottom-right corner.',
      [TRANSLATIONS.DOWNLOAD_ALL]: 'Download All',
      [TRANSLATIONS.INCIDENTS_PER_AREA]: 'SIOs per area',
      [TRANSLATIONS.RESET]: 'Reset',
      [TRANSLATIONS.SAFE]: 'Safe',
      [TRANSLATIONS.PLANT]: 'CD/Plant',
      [TRANSLATIONS.ID]: 'ID',
      [TRANSLATIONS.SHARP]: 'Sharp',
      [TRANSLATIONS.STATUS]: 'Status',
      [TRANSLATIONS.REASON]: 'Reason to block',
      [TRANSLATIONS.ACTIONS]: 'Actions',
      [TRANSLATIONS.BLOCKED]: 'Blocked',
      [TRANSLATIONS.MEDIACAL_EXAMINATION]: 'Medical check-up',
      [TRANSLATIONS.NO_DATA]: 'No Data',
      [TRANSLATIONS.NO_DATA_FOUND_SO_FAR]: 'So far no data has been found for this section',
      [TRANSLATIONS.CURRENT_PROGRESS]: 'Curr. Progress',
      [TRANSLATIONS.TOTAL_PROGRESS]: 'Total Progress',
      [TRANSLATIONS.LAST]: 'Last',
      [TRANSLATIONS.DOWNLOAD_ROUTINES_DB]: 'Download the Routines data base',
      [TRANSLATIONS.SEARCH_LOCATION]: 'Search location',
      [TRANSLATIONS.NO_MATCHES_FOUND]: 'No matches found',
      [TRANSLATIONS.CONSEQUENCES]: 'Consequences',
      [TRANSLATIONS.SELECT_SUBZONE_OR_DISTRIBUTION_CENTER]: 'Please select a subzone or a distribution center to view routines progress of each user',
      [TRANSLATIONS.CRITICAL_CLIENTS]: 'Critical clients',
      [TRANSLATIONS.BY_BU]: 'By BU',
      [TRANSLATIONS.BY_POSITION]: 'By Position',
      [TRANSLATIONS.BY_ROUTINES]: 'By Routines',
      // we need to translate this to english
      [TRANSLATIONS.DIARIA_LOGÍSTICA]: 'Diaria Logística',
      [TRANSLATIONS.RUTINA_MATUTINA_DE_REPARTO]: 'Rutina matutina de reparto (delivery)',
      [TRANSLATIONS.RUTINA_DE_CAMBIO_DE_TURNO]: 'Rutina de cambio de turno (warehouse)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_ALMACÉN]: 'Rutina semanal de Almacén',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_DIST]: 'Rutina semanal de Distribución',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PEOPLE]: 'Rutina semanal de People',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_FLOTA]: 'Rutina semanal de Flota',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PLANNING]: 'Rutina semanal de Planning (Financiera VLC)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_SAFETY]: 'Rutina semanal de Safety (Financiera VLC)',
      [TRANSLATIONS.RUTINE_SEMANAM_DE_GESTION]: 'Rutina semanal de Gestión',
      [TRANSLATIONS.AT_HOME]: 'At home',
      [TRANSLATIONS.HOSPITAL]: 'Hospital',
      [TRANSLATIONS.STABLE]: 'Stable',
      [TRANSLATIONS.STABLE_WITH_OXYGEN]: 'Stable with oxygen',
      [TRANSLATIONS.SERIOUS_WITH_OXYGEN]: 'Serious with oxygen',
      [TRANSLATIONS.SERIOUS_WITHOUT_OXYGEN]: 'Serious without oxygen',
      [TRANSLATIONS.LAST_UPDATED_ON_MONTH_DATE_DAY]: 'Last Updated {{month}} {{date}}, {{day}} days ago.',
      [TRANSLATIONS.LAST_UPDATED_TODAY]: 'Last Updated Today.',
      [TRANSLATIONS.UPDATE_USER_STATUS_TODAY_MONTH_DATE]: 'Update status of {{user}} today {{month}} {{date}}.',
      [TRANSLATIONS.WHERE_IS_USER]: 'Where is {{user}}?',
      [TRANSLATIONS.REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS]: 'Remember that sending this information is very important for us to give you the best possible support through the quarantine process.',
      [TRANSLATIONS.SAVE]: 'Save',
      [TRANSLATIONS.ADD_COMMENT]: 'Add Comment',
      [TRANSLATIONS.COMMENT_CREATED_AT]: '{{date}} at {{time}}',
      [TRANSLATIONS.COMMENT_SHOULD_BE_UNDER_N_CHARACTER]: 'Comment should be under {{maxSize}} characters',
      [TRANSLATIONS.EDIT]: 'Edit',
      [TRANSLATIONS.DELETE]: 'Delete',
      [TRANSLATIONS.DELETING]: 'Deleting',
      [TRANSLATIONS.PARTICIPANT]: 'Participant',
      [TRANSLATIONS.OWNER]: 'Owner',
      [TRANSLATIONS.IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW]: 'If not necessary, return to update tomorrow.',
      [TRANSLATIONS.STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH]: 'Status updated as of today {{month}} {{date}}.',
      [TRANSLATIONS.UPDATED_TODAY_AT_TIME]: 'Updated today at {{time}}',
      [TRANSLATIONS.YOUR_QUARANTINE_HAS]: 'Your quarantine has',
      [TRANSLATIONS.CONCLUDED]: 'concluded',
      [TRANSLATIONS.PHYSICAL_STATE]: 'Physical state',
      [TRANSLATIONS.PILLAR]: 'Pillar',
      [TRANSLATIONS.MANAGEMENT]: 'Management',
      [TRANSLATIONS.PLANNING]: 'Planning',
      [TRANSLATIONS.DISTRIBUTION]: 'Distrib.',
      [TRANSLATIONS.FLEET]: 'Fleet',
      [TRANSLATIONS.STOCK]: 'Stock',
      [TRANSLATIONS.PEOPLE]: 'People',
      [TRANSLATIONS.WAREHOUSE]: 'Warehouse',
      [TRANSLATIONS.NOTE]: 'Note',
      [TRANSLATIONS.SEARCH_KPI]: 'Search KPIs',
      [TRANSLATIONS.SEARCH_ROUTINE]: 'Search Routines',
      [TRANSLATIONS.NO_RESULTS_FOUND]: 'No results found',
      [TRANSLATIONS.SEARCH_TO_ADD_USERS]: 'Search To Add Users',
      [TRANSLATIONS.ACTION_LOG_TABLE_FOR_LOCATION_NAME]: 'Action log table for {{locationName}}',
      [TRANSLATIONS.VIOLENCE_PREVENTION]: 'Violence prevention',
      [TRANSLATIONS.DELETE_FILTERS]: 'Delete filters',
      [TRANSLATIONS.ALL_ROUTINES]: 'All routines',
      [TRANSLATIONS.ALL_KPIS]: 'All KPIs',
      [TRANSLATIONS.ALL_USERS]: 'All users',
      [TRANSLATIONS.SELECT_THE_ROUTINE_YOU_WANT_TO_SEE]: 'Select the routine you want to see',
      [TRANSLATIONS.SELECT_THE_KPIS_YOU_WANT_TO_SEE]: 'Select the KPIs you want to see',
      [TRANSLATIONS.SELECT_THE_USERS_YOU_WANT_TO_SEE]: 'Select the users you want to see',
      [TRANSLATIONS.NO_DUE_DATE_SELECTED]: 'No due date selected',
      [TRANSLATIONS.DELETE_COMMENT]: 'Delete comment',
      [TRANSLATIONS.CLICK_HERE_DROP_TO_UPLOAD]: 'Click here or drop a file to upload!',
      [TRANSLATIONS.DROP_IT_HERE]: 'Drop it here',
      [TRANSLATIONS.FILE_TYPE_NOT_ACCEPTED_SORRY]: 'File type not accepted, sorry!',
      [TRANSLATIONS.MAXIMUM_ALLOWED_FILE_SIZE]: 'Maximum allowed file size : {{size}}',
      [TRANSLATIONS.REMOVE_FILE]: 'Remove file',
      [TRANSLATIONS.UPLOADING]: 'Uploading',
      [TRANSLATIONS.UPLOAD]: 'Upload',
      [TRANSLATIONS.ERROR]: 'Error',
      [TRANSLATIONS.RETRY]: 'Retry',
      [TRANSLATIONS.FILE_IS_TOO_LARGE]: 'File is too large',
      [TRANSLATIONS.SERVER_ERROR]: 'Server error',
      [TRANSLATIONS.PROCESSING_YOUR_FILE]: 'Processing your file',
      [TRANSLATIONS.PROCESSING_FAILED_WITH_MESSAGE]: 'Processing failed {{msg}}',
      [TRANSLATIONS.CLICK_TO_VISIT]: 'Click to visit {{location}}',
      [TRANSLATIONS.REUPLOAD]: 'Re-Upload',
      [TRANSLATIONS.FINISHED_FILE_PROCESSING]: 'Finished file processing',
      [TRANSLATIONS.DONE]: 'Done',
      [TRANSLATIONS.ALL_ITEMS_ARE_SELECTED]: 'All items are selected',
      [TRANSLATIONS.CLEAR_SEARCH]: 'Clear search',
      [TRANSLATIONS.NO_OPTIONS]: 'No options',
      [TRANSLATIONS.SELECT_ALL]: 'Select all',
      [TRANSLATIONS.SELECT_ALL_FILTERED]: 'Select all (filtered)',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_HEADING]: 'Routine compliance',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLETED_PERCENTAGE]: 'routine %',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLIANCE]: 'Compliance',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_EXECUTED]: 'Executed',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PENDING]: 'Pending',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_RANKING]: 'DC Ranking for MAZ',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PLANNED]: 'Planes',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_SEARCH]: 'Search By GRO...',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_REGION]: 'Country/ Region',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_BU]: 'Per BU',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_CD]: 'CD',
      [TRANSLATIONS.TOOLKIT_HEADING]: 'Violence prevention',
      [TRANSLATIONS.TOOLKIT_PREVIOUS_MONTH]: 'Previous month',
      [TRANSLATIONS.META]: 'Goal',
      [TRANSLATIONS.NOTES]: 'Notes for',
      [TRANSLATIONS.COMMENT_BY]: 'Comment written by',
      [TRANSLATIONS.LATEST_EDIT]: 'Latest Edit',
      [TRANSLATIONS.SAVE_NOTE]: 'Save note',
      [TRANSLATIONS.SIF_STATISTICS]: 'SIF Statistics',
      [TRANSLATIONS.USER_TYPE]: 'User Type',
      [TRANSLATIONS.USER_ROLE_TRANSLATION]: 'User Role',
      [TRANSLATIONS.UPDATE]: 'Update',
      [TRANSLATIONS.PASSWORD_RECOVERY]: 'Password recovery',
      [TRANSLATIONS.UPDATE_YOUR_PASSWORD_TO_ACCESS]: 'Update your password to access',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED]: 'Your password was successfully restored.',
      [TRANSLATIONS.YOU_CAN_USE_THE_APP_AGAIN]: '¡You can use the app again!',
      [TRANSLATIONS.REDIRECT_TIMEOUT]: 'Please wait while you are being redirected. If you are not being redirected after 10 seconds click',
      [TRANSLATIONS.HERE]: 'Here',
      [TRANSLATIONS.PREVENTION_CAMPAIGNS]: 'Preventive Campaign',
      [TRANSLATIONS.BEST_PRACTICES]: 'Best Practices',
      [TRANSLATIONS.MONTHLY_TOTAL]: 'Monthly Total',
      [TRANSLATIONS.BOARDS]: 'Boards',
      [TRANSLATIONS.OWD]: 'OWDs',
      [TRANSLATIONS.PDT]: 'PDTs',
      [TRANSLATIONS.SAFETY_NEWS]: 'Safety News',
      [TRANSLATIONS.TOP]: 'TOP',
      [TRANSLATIONS.BOTTOM]: 'BOTTOM',
      [TRANSLATIONS.SELECT_MONTH]: 'Select Month',
      [TRANSLATIONS.PYRAMID]: 'PYRAMID',
      [TRANSLATIONS.PLEASE_UPLOAD_A_FILE]: 'Please upload a file',
      [TRANSLATIONS.LAST_UPDATED_ON]: 'Last updated on {{monthAndYear}}',
      [TRANSLATIONS.TELEMETRY]: 'Telemmetry',
      [TRANSLATIONS.CURRENT_MONTH]: 'Current month',
      [TRANSLATIONS.BY_CATEGORY]: 'By category',
      [TRANSLATIONS.TELEMETRY_BY_BU]: 'Telemmetry by BU',
      [TRANSLATIONS.REGULAR_SPEEDING]: 'Regular speeding',
      [TRANSLATIONS.CRITICAL_SPEEDING]: 'Critical speeding',
      [TRANSLATIONS.CURVE_SPEEDING]: 'Curve speeding',
      [TRANSLATIONS.HARSH_TURNING]: 'Harsh turning',
      [TRANSLATIONS.SEATBELT]: 'Seatbelt',
      [TRANSLATIONS.VEHICLE]: 'Vehicle',
      [TRANSLATIONS.CLICK_HERE]: 'Click Here!',
      [TRANSLATIONS.ACTION_LOGS]: 'Action Logs',
      [TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING]: 'Production Performance Tracking',
      [TRANSLATIONS.BY]: 'by',
      [TRANSLATIONS.COUNTRY]: 'Country',
      [TRANSLATIONS.SIF_ROUTE]: 'SIF Route',
      [TRANSLATIONS.SIF_COMPLANICE]: 'SIF Compliance',
      [TRANSLATIONS.SAFETY_TRAINING]: 'Capacitaciones',
      [TRANSLATIONS.QR_CODE]: 'QR Code',
      [TRANSLATIONS.INCORRECT_EMAIL_PASSWORD]: 'Incorrect Email/password.',
      [TRANSLATIONS.INVALID_CREDENTIALS_TRY_AGAIN]: 'Invalid Credentials. Try Again!',
      [TRANSLATIONS.MANAGEMENT_TOOLS]: 'Management Tools',
      [TRANSLATIONS.THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG]: 'There is no note added for this action log...',
      [TRANSLATIONS.THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET]: 'There are no action logs in this column yet.',
      [TRANSLATIONS.ADD_ACTION_LOG]: 'Add action log',
      [TRANSLATIONS.OPEN]: 'Open',
      [TRANSLATIONS.IN_PROGRESS]: 'In progress',
      [TRANSLATIONS.IN_REVIEW]: 'In review',
      [TRANSLATIONS.COMPLETED]: 'Completed',
      [TRANSLATIONS.WRITE_ACTION_HERE]: 'Write action here',
      [TRANSLATIONS.SUCCESS_CRITERIA]: 'SUCCESS CRITERIA',
      [TRANSLATIONS.WRITE_SUCCESS_CRITERIA_HERE]: 'Write success criteria here...',
      [TRANSLATIONS.REVIEW_IN]: 'REVIEW IN',
      [TRANSLATIONS.SAVE_ACTION_LOG]: 'Save action log',
      [TRANSLATIONS.CREATE_ACTION_LOG]: 'Create action log',
      [TRANSLATIONS.SHOULD_BE_CLOSED_TODAY]: 'Should be closed today',
      [TRANSLATIONS.SHOULD_HAVE_BEEN_CLOSED]: 'Should have been closed',
      [TRANSLATIONS.DAY]: 'day',
      [TRANSLATIONS.DAYS]: 'days',
      [TRANSLATIONS.TO_BE_CLOSED]: 'to be closed',
      [TRANSLATIONS.THERE_ARE_NO_COMMENTS_YET]: 'There are no comments yet',
      [TRANSLATIONS.CREATED]: 'Created',
      [TRANSLATIONS.DUE_DATE]: 'Due date',
      [TRANSLATIONS.SINCE]: 'since',
      [TRANSLATIONS.IN]: 'in',
      [TRANSLATIONS.WRITE_A_NOTE]: 'Write a note',
      [TRANSLATIONS.WAIT_A_MOMENT]: 'Wait a moment',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE]: 'If you leave it without saving, your changes will be lost, you want to stay here?',
      [TRANSLATIONS.LEAVE_WITHOUT_SAVE]: 'Leave without save',
      [TRANSLATIONS.DELETE_CARD]: 'Delete card',
      [TRANSLATIONS.NOT_ENOUGH_PERMISSIONS_TO_ACCESS]: 'No web access permissions. Please Contact a Super User.',
      [TRANSLATIONS.WEB_ACCESS]: 'Web Access',
      [TRANSLATIONS.INDICATORS]: 'Indicators',
      [TRANSLATIONS.DVR_CENTRAL_BAJIO]: 'DRV Central Bajio',
      [TRANSLATIONS.METROPOLITAN_SBU]: 'Metropolitan SBU',
      [TRANSLATIONS.METROPOLITAN]: 'Metropolitan',
      [TRANSLATIONS.CD_SAN_PABLO]: 'CD San ​​Pablo',
      [TRANSLATIONS.DELIVERY_DRIVER]: 'Delivery Driver',
      [TRANSLATIONS.FORKLIFTERS]: 'Forklifters',
      [TRANSLATIONS.WAREHOUSE_AUXILIAR]: 'Warehouse Auxiliar',
      [TRANSLATIONS.DELIVERY_AUXILIAR]: 'Delivery Auxiliar',
      [TRANSLATIONS.SKAP]: 'SKAP',
      [TRANSLATIONS.SORRY_THERE_WAS_AN_ERROR]: 'Sorry, there was an error',
      [TRANSLATIONS.RELOAD_PAGE]: 'reload page',
      [TRANSLATIONS.IMAPACTED_HC]: 'HC impacted',
      [TRANSLATIONS.FUNCTIONAL_INDUCTION]: 'Induction functional ',
      [TRANSLATIONS.TECHNICAL_SKILLS]: 'Technical skills',
      [TRANSLATIONS.AUTONOMOUS_SKILLS]: 'Autonomous skills',
      [TRANSLATIONS.FUNCTIONAL]: 'Functional',
      [TRANSLATIONS.TECHNICAL]: 'Technical',
      [TRANSLATIONS.AUTONOMOUS]: 'Autonomous',
      [TRANSLATIONS.AUTONOMY_LEVEL]: 'Autonomy level',
      [TRANSLATIONS.HEAD_COUNT]: 'Head count',
      [TRANSLATIONS.RAMP_UP]: 'Ramp up',
      [TRANSLATIONS.AREA]: 'Area',
      [TRANSLATIONS.HC_TOTAL]: 'HC total',
      [TRANSLATIONS.DOWNLOAD_EXCEL]: 'Download Excel',
      [TRANSLATIONS.ALL_THE_TEAM]: 'All the team',
      [TRANSLATIONS.NEW_COLLABORATORS]: 'New collaborators',
      [TRANSLATIONS.OLD_COLLABORATORS]: 'Old collaborators',
      [TRANSLATIONS.DELAY_TASK]: 'Delay task',
      [TRANSLATIONS.IN_WAREHOUSE]: 'In warehouse',
      [TRANSLATIONS.IN_FACTORY]: 'In factory',
      [TRANSLATIONS.CONDITION_WAS_CLOSED_IMMEDIATELY]: 'The condition was closed immediately',
      [TRANSLATIONS.COULD_IT_BE_SIF]: 'Could it be a SIF?',
      [TRANSLATIONS.SIF_PRECURSOR]: 'SIF Precursor',
      [TRANSLATIONS.SIF_POTENTIAL]: 'SIF Potencial',
      [TRANSLATIONS.DEPARTMENT]: 'Department',
      [TRANSLATIONS.SAP_SHARP]: '#SAP/SHARP',
      [TRANSLATIONS.CREATE_AN_ACCOUNT]: 'Create an account',
      [TRANSLATIONS.DONT_HAVE_AN_ACCOUNT]: "Don't have an account",
      [TRANSLATIONS.ALREADY_REGISTERED]: 'Already registered',
      [TRANSLATIONS.REGISTER_ON]: 'Register on',
      [TRANSLATIONS.TERMS_AND_CONDITIONS]: 'I have read and agree to the terms & conditions',
      [TRANSLATIONS.FOLLOW_THE_INSTRUCTIONS_RECEIVED]: 'Follow the instructions you have received in your email to create a password',
      [TRANSLATIONS.TOO_SHORT]: 'too short',
      [TRANSLATIONS.YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS]: 'You must accept terms and conditions',
      [TRANSLATIONS.ENTER_A_CORRECT_EMAIL_FORMAT]: 'enter a correct email format',
      [TRANSLATIONS.OPERATION]: 'Operation',
      [TRANSLATIONS.CATEGORY]: 'Category',
      [TRANSLATIONS.PROCEDURE]: 'Procedure',
      [TRANSLATIONS.PRIORITY_T]: 'Priority',
      [TRANSLATIONS.LOGISTICS]: 'Logistics',
      [TRANSLATIONS.YOU_MUST_ENTER_YOUR_OPERATION_TYPE]: 'You must select an operation type',
      [TRANSLATIONS.DISTRIBUTION_CENTER]: 'Distribution center',
      [TRANSLATIONS.FACTORY]: 'Factory',
      [TRANSLATIONS.COPY_QR_CODE]: 'Copy QR code',
      [TRANSLATIONS.AN_ERROR_HAS_OCCURRED]: 'An error has occurred.',
      [TRANSLATIONS.FILE_UPLOADED_SUCCESSFULLY]: 'File uploaded successfully.',
      [TRANSLATIONS.SUBAREA]: 'Subarea',
      [TRANSLATIONS.NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE]: 'Note: Uploaded files will not be reflected immediately, once you upload all your files please refresh the page.',
      [TRANSLATIONS.YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES]: 'Your file has started to load, it may take a few minutes.',
      [TRANSLATIONS.USER_UPDATED_SUCCESSFULLY]: 'User updated successfully.',
      [TRANSLATIONS.UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE]: 'The updated user now does not have any role, please assign a permission to the user in permissions tab.',
      [TRANSLATIONS.SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE]: 'Search the user by QR code to configure',
      [TRANSLATIONS.SELECT_LOCATION]: 'Selecte a location',
      [TRANSLATIONS.OPERATIONAL]: 'Operational',
      [TRANSLATIONS.CORPORATE]: 'Corporate',
      [TRANSLATIONS.DOWNLOAD_DATA]: 'Download data',
      [TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED]: 'The data for the location(s) that are filtered in the top filter bar will be downloaded.',
      [TRANSLATIONS.SELECT_DOCUMENT]: 'Select document',
      [TRANSLATIONS.SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD]: 'Select the document you want to download',
      [TRANSLATIONS.DOWNLOADING_DATA]: 'Downloading data...',
      [TRANSLATIONS.CHOOSE_HERE]: 'Choose here',
      [TRANSLATIONS.WITHOUT_POSITION]: 'Without position',
      [TRANSLATIONS.RETRY_AGAIN]: 'Retry',
      [TRANSLATIONS.SOMETHING_WENT_WRONG]: 'Something went wrong',
      [TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE]: 'Download failed. Retry the download please.',
      [TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE]: 'The data is taking a long time to download, a download link will be sent to your email to continue.',
      [TRANSLATIONS.JOB_TITLE]: 'Job Title',
      [TRANSLATIONS.UNBLOCKED]: 'Unblocked',
      [TRANSLATIONS.NEXT]: 'Next',
      [TRANSLATIONS.PREVIOUS]: 'Previous',
      [TRANSLATIONS.TOTAL_PAGES]: 'Total Pages',
      [TRANSLATIONS.REMOVE_FILTERS]: 'Reset filters',
      [TRANSLATIONS.UPLOAD_TEMPLATE]: 'Upload template',
      [TRANSLATIONS.ADD_NEW_USER]: 'Add new user',
      [TRANSLATIONS.DONT_FORGET_TO_SET_AN_ACCESS_TO_LOCATION]: 'Don\'t forget to set an access to {location}',
      [TRANSLATIONS.UPDATE_TEMPLATE]: 'Update 5s template',
      [TRANSLATIONS.ERROR_TO_SAVE_CHANGES_THE_USER_NEEDS_THE_ACCESS_TO_BE_SAME_AS_WORKS_AT_SELECTED]: 'Error to save changes. The user needs the access to be same as works at selected.',
      [TRANSLATIONS.USER_DISABLED_CONTACT_TO_YOUR_SUPERVISOR_OR_SUPPORT]: 'User disabled, contact your supervisor or support.',
      [TRANSLATIONS.NO_IMAGE]: 'No image',
      [TRANSLATIONS.START_DATE]: 'Start date',
      [TRANSLATIONS.END_DATE]: 'End date',
      [TRANSLATIONS.SEARCH_BY_NAME]: 'Search by Name',
      [TRANSLATIONS.SHARP_AND_DESCRIPTION]: 'Sharp and description',
      [TRANSLATIONS.NO_RECORDS_FOUND]: 'No records found',
      [TRANSLATIONS.DO_NOT_USE_ONE_OF_YOUR_OLD_PASSWORDS]: 'Do not use one of your old passwords',
      [TRANSLATIONS.TRAINING]: 'Training',
      [TRANSLATIONS.CAPITAL_LETTER]: 'Capital letter',
      [TRANSLATIONS.LOWER_CASE]: 'Lowercase letter',
      [TRANSLATIONS.NUMBER]: 'Number',
      [TRANSLATIONS.SPECIAL_CHARACTER]: 'Special Character',
      [TRANSLATIONS.CONTAIN]: 'Contain',
      [TRANSLATIONS.PASSWORD_MUST]: 'Your password must',
      [TRANSLATIONS.VERIFY_PASSWORD_CRITERIA]: 'Verify password criteria',
      [TRANSLATIONS.REPORT_SECTION]: 'Report Section',
      [TRANSLATIONS.EXECUTION]: 'Execution',
      [TRANSLATIONS.RESULTS]: 'Results',
      [TRANSLATIONS.TRUCKS]: 'Trucks',
      [TRANSLATIONS.USER_PROGRESS]: 'User progress',
      [TRANSLATIONS.CLOSING_IMAGE]: 'Closing image',
      [TRANSLATIONS.SEE_MORE]: 'See more',
      [TRANSLATIONS.SEE_LESS]: 'See less',
      [TRANSLATIONS.CHANGED_ROLE_TO]: 'Changed role {{role1}} ({{from}}) to {{role2}} ({{to}})',
      [TRANSLATIONS.SECTOR]: 'Sector',
      [TRANSLATIONS.DPO_ACADEMY_MATERIAL]: 'DPO Academy Material',
      [TRANSLATIONS.YEAR]: 'Year',
      [TRANSLATIONS.MONTH]: 'Month',
      [TRANSLATIONS.SUMMARY]: 'Summary',
      [TRANSLATIONS.ERROR_GETTING_DISTRIBUTION_CENTER_INFO]: 'Error getting distribution center info',
      [TRANSLATIONS.PLEASE_SELECT_A_BU_TO_SHOW_CHARTS]: 'Please select a BU to show charts',
      [TRANSLATIONS.ROUTINE_EFFECTIVENESS]: 'Team room effectiveness',
      [TRANSLATIONS.EXTRA_MATERIAL]: 'Extra Material',
      [TRANSLATIONS.ADHERENCE]: 'Adherence',
      [TRANSLATIONS.WORKSTATIION_EFFECTIVENESS]: 'Workstation Effectiveness',
      [TRANSLATIONS.DOWNLOAD_HC]: 'Download HC',
      [TRANSLATIONS.UNSAFE_CONDITION]: 'Unsafe condition',
      [TRANSLATIONS.SAFE_CONDITION]: 'Safe condition',
      [TRANSLATIONS.UNSAFE_ACT]: 'Unsafe act',
      [TRANSLATIONS.SAFE_ACT]: 'Safe act',
      [TRANSLATIONS.TO_SEE_THE_DETAILS_OF_AN_ACI_CLICK_ON_A_CARD]: 'To see the details of an ACI, click on a card.',
      [TRANSLATIONS.NO_SIF]: 'No SIF',
      [TRANSLATIONS.TYPE_OF_REPORT]: 'Type of Report',
      [TRANSLATIONS.SEARCH_BY_FOLIO]: 'Search by folio',
      [TRANSLATIONS.SELECT_DATE]: 'Select Date',
      [TRANSLATIONS.NO_PHOTOS]: 'No Photos',
      [TRANSLATIONS.GOLDEN_RULE]: 'Golden rule',
      [TRANSLATIONS.IT_DOES_BREAK]: 'It does break',
      [TRANSLATIONS.IT_DOES_NOT_BREAK]: 'It does not break',
      [TRANSLATIONS.DO_YOU_WANT_TO_CLOSE_THE_CONDITION_AT_THIS_TIME]: 'Do you want to close the condition at this time?',
      [TRANSLATIONS.WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_CONDITION]: 'Write here the action taken to close the condition',
      [TRANSLATIONS.WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_ACT]: 'Write here the action taken to close the act',
      [TRANSLATIONS.CLOSE_LATER]: 'Close later',
      [TRANSLATIONS.CLOSE_RIGHT_NOW]: 'Close right now',
      [TRANSLATIONS.CONDITION_REGISTERED_SUCCESSFULLY]: 'Condition registered successfully!',
      [TRANSLATIONS.ACT_REGISTERED_SUCCESSFULLY]: 'Act registered successfully!',
      [TRANSLATIONS.CONDITION_CLOSED_SUCCESSFULLY]: 'Condition closed successfully!',
      [TRANSLATIONS.THE_CONDITION_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT]: 'The condition was recorded and will be sent to the appropriate department.',
      [TRANSLATIONS.THE_ACT_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT]: 'The act was recorded and will be sent to the appropriate department.',
      [TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED]: 'The condition was successfully recorded and closed.',
      [TRANSLATIONS.START_ANOTHER_REPORT]: 'Start another report',
      [TRANSLATIONS.GO_TO_SAFETY]: 'Go to Safety',
      [TRANSLATIONS.RETURN_SIO]: 'Return SIO',
      [TRANSLATIONS.CLOSE_SIO]: 'Close SIO',
      [TRANSLATIONS.UNASSIGNED_SIOS]: 'Unassigned SIOs',
      [TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_SIO]: 'Write here the reason for returning this SIO',
      [TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_ACT]: 'Write here the reason for returning this act',
      [TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_CONDITION]: 'Write here the reason for returning this condition',
      [TRANSLATIONS.RETURN_SIO_QUESTION_TEXT]: 'Return SIO?',
      [TRANSLATIONS.RETURN_ACT]: 'Return Act?',
      [TRANSLATIONS.RETURN_CONDITION]: 'Return Condition?',
      [TRANSLATIONS.IF_THE_SIO_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN]: 'If the SIO does not belong to you, press on ”Yes, return”.',
      [TRANSLATIONS.IF_THE_CONDITION_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN]: 'If the Condition does not belong to you, press on ”Yes, return”.',
      [TRANSLATIONS.IF_THE_ACT_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN]: 'If the Act does not belong to you, press on ”Yes, return”.',
      [TRANSLATIONS.YES_RETURN]: 'Yes, return',
      [TRANSLATIONS.RETURNED_SIO]: 'Returned SIO',
      [TRANSLATIONS.THE_SIO_WAS_RETURNED_TO_BE_REASSIGNED]: 'The SIO was returned to be reassigned.',
      [TRANSLATIONS.ACT_CLOSED_SUCCESSFULLY]: 'Act closed successfully!',
      [TRANSLATIONS.CLOSE_MESSAGE]: 'Close message',
      [TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED]: 'The act was successfully recorded and closed.',
      [TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_CLOSED]: 'The condition was successfully closed.',
      [TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_CLOSED]: 'The act was successfully closed.',
      [TRANSLATIONS.DO_YOU_WANT_TO_CLOSE_THE_ACT_AT_THIS_TIME]: 'Do you want to close the act at this time?',
      [TRANSLATIONS.EDIT_SIO]: 'Edit SIO',
      [TRANSLATIONS.POSTPONE_SIO]: 'Postpone SIO',
      [TRANSLATIONS.POSTPONE_CONDITION]: 'Postpone condition',
      [TRANSLATIONS.POSTPONE_ACT]: 'Postpone act',
      [TRANSLATIONS.POSTPONE]: 'Postpone',
      [TRANSLATIONS.CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_CONDITION]: 'Choose a date for when you will close this condition',
      [TRANSLATIONS.CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_ACT]: 'Choose a date for when you will close this act',
      [TRANSLATIONS.CONDITION_POSTPONED_SUCCESSFULLY]: 'Condition postponed successfully!',
      [TRANSLATIONS.ACT_POSTPONED_SUCCESSFULLY]: 'Act postponed successfully!',
      [TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_POSTPONED]: 'The condition was successfully postponed.',
      [TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_POSTPONED]: 'The act was successfully postponed.',
      [TRANSLATIONS.OK]: 'Ok',
      [TRANSLATIONS.UPLOAD_EVIDENCE_OF_ACI_CREATION]: 'Upload evidence of ACI creation',
      [TRANSLATIONS.UPLOAD_EVIDENCE_TO_CLOSE_ACI]: 'Upload evidence to close ACI',
      [TRANSLATIONS.SIF]: 'SIF',
      [TRANSLATIONS.RETURNED_BY]: 'Returned by',
      [TRANSLATIONS.RETURN_CAUSE]: 'Return cause',
      [TRANSLATIONS.OPENING_OF_QUESTIONS]: 'Opening of questions',
      [TRANSLATIONS.FORBIDDEN_MISSING_PERMISSIONS]: 'Forbidden: Missing Permissions',
      [TRANSLATIONS.START_WORKING_DAY]: 'Start working day',
      [TRANSLATIONS.POWER_BI_SIOS_T2]: 'Power BI SIOs T2',
      [TRANSLATIONS.DETAILS]: 'Details',
      [TRANSLATIONS.START_MONTH]: 'Start month',
      [TRANSLATIONS.END_MONTH]: 'End month',
      [TRANSLATIONS.REPORTED_BY]: 'Reported by',
      [TRANSLATIONS.ASSIGNED_TO]: 'Assigned to',
      [TRANSLATIONS.THE_GOLDEN_RULE_IDENTIFIES_RISKS_WHEN_BREAKING_THE_RULES_PROVIDING_AN_ADDITIONAL_LAYER_OF_SECURITY_IF_YOU_FAIL_TO_COMPLY_THE_USER_WILL_BE_ASKED_FOR_EVIDENCE_WHEN_CLOSING_THE_ACI]: 'The Golden Rule identifies risks when breaking the rules, providing an additional layer of security. If you fail to comply, the user will be asked for evidence when closing the ACI.',
      [TRANSLATIONS.SIF_BEING_SERIOUS_INJURIES_AND_FATALITIES_WHICH_TRANSLATES_TO_SERIOUS_INJURIES_AND_FATALITIES]: 'SIF being Serious injuries and fatalities, which translates to Serious injuries and fatalities',
      [TRANSLATIONS.IS_IT_AN_ACI_TYPE_SIF]: 'Is it an ACI type SIF?',
      [TRANSLATIONS.YOU_HAVE_REACH_THE_LIMIT_OF_REPORTS_PLEASE_TRY_AGAIN_AT]: 'You have reach the limit of reports, please try again at {{date}}',
      [TRANSLATIONS.FOLLOW_THE_GOLDEN_RULE]: 'Follow the golden rule',
      [TRANSLATIONS.BREAKS_THE_GOLDEN_RULE]: 'Breaks the golden rule',
      [TRANSLATIONS.ACI_CONSIDERED]: 'ACI Considered',
      [TRANSLATIONS.SERIOUS_INJURIES_AND_FATALITIES]: 'Serious injuries and fatalities',
      [TRANSLATIONS.LIST_OF_THE_USERS_THAT_ARE_ASSIGNED_TO_THE_SELECTED_LOCATION]: 'List of the users that are assigned to the selected locations.',
      [TRANSLATIONS.LIST_OF_THE_USERS_THAT_HAVE_ACCESS_TO_THE_SELECTED_LOCATION_USERS_MAY_APPEAR_MORE_THAN_ONCE_IN_DIFFERENT_LOCATIONS]: 'List of the users that have access to the selected location. User may appear more than once in different locations.',
      [TRANSLATIONS.YOU_DONT_HAVE_ACCESS_TO_LOCATION_PLEASE_CONTACT_SUPERUSER]: 'Without locations, contact a super user.',
      [TRANSLATIONS.DOWNLOAD_SKAP_HC_TITLE]: 'SKAP HC',
      [TRANSLATIONS.DOWNLOAD_SKAP_HC_DESCRIPTION]: 'List of the users that have a position and permission that give you SKAP that belong to the selected locations.',
      [TRANSLATIONS.SHOW_DELETED_USERS]: 'Show deleted users',
      [TRANSLATIONS.FIND_USERS_DESCRIPTION]: 'To find users, use SHARP or email. Enter at least 6 characters or letters in the search bar located at the top of the screen.',
      [TRANSLATIONS.SEARCH_FOR_DELETED_USERS_BY_SHARP_EMAIL_QR_OR_PIN]: 'Search for deleted users by SHARP, QR, pin or email.',
      [TRANSLATIONS.REHABILITATE_USER]: 'Rehabilitate user',
      [TRANSLATIONS.GRADES_CURRENT_STATUS]: 'Current Status',
      [TRANSLATIONS.MISSING_DATA_TO_FILL]: 'Missing data to fill',
      [TRANSLATIONS.WORKS_AT_FIELD_NEEDS_TO_BE_ADDED_TO_USER]: 'The “Works in” field needs to be added to the user.',
      [TRANSLATIONS.USER_NEEDS_TO_BE_ASSIGNED_A_POSITION]: 'The user needs to be assigned a position.',
      [TRANSLATIONS.USERS_HIRING_DATE_NEEDS_TO_BE_ADDED]: "The user's hiring date needs to be added.",
      [TRANSLATIONS.THIS_EMAIL_IS_ALREADY_REGISTER]: 'This email is already register',
      [TRANSLATIONS.EDIT_OPERATIONS]: 'Edit Operations',
      [TRANSLATIONS.ERROR_LOADING_INFORMATION]: 'Error loading information',
      [TRANSLATIONS.EDIT_OPERATION_LOCATION_NOT_SUPPORTED]: 'This is a DC location therefore cannot be edited',
      [TRANSLATIONS.SUPER_USER_DOESNT_HAVE_ACCESS_TO_LOCATION]: 'You don\'t have access to this location',
      [TRANSLATIONS.STRUCTURE_TREE]: 'Structure Tree',
      [TRANSLATIONS.RELOAD_DATA]: 'Reload Data',
      [TRANSLATIONS.DEVIATIONS]: 'Deviations',
      [TRANSLATIONS.USABILITY_DPO]: 'Usability DPO',
      [TRANSLATIONS.USABILITY_DPO_HEADING]: 'Usability DPO 2024',
      [TRANSLATIONS.RULES]: 'Rules',
      [TRANSLATIONS.ACTION_PLANS]: 'Action plans',
      [TRANSLATIONS.ZOOM_ROUTINES]: 'Zoom Routines',
      [TRANSLATIONS.FIVE_S]: '5s',
      [TRANSLATIONS.SCORECARD]: 'Scorecard',
      [TRANSLATIONS.TEAM_ROOMS]: 'TeamRooms',
      [TRANSLATIONS.WORKSTATION]: 'Workstation',
      [TRANSLATIONS.PLASSE_USE_SINGAL_SIGN_ON_TO_ACCESS]: 'Please use singal sign on to access system ',
      [TRANSLATIONS.ROAD_SAFETY]: 'Road Safety',
      [TRANSLATIONS.ROAD_SAFETY_PLANNING]: 'Planning',
      [TRANSLATIONS.CRITICAL_PATHS]: 'Critical routes',
      [TRANSLATIONS.DRIVERS]: 'Drivers',
      [TRANSLATIONS.RESOURCES]: 'Resources',
      [TRANSLATIONS.TRUCK_ARCHIVE]: 'Truck Matrix File',
      [TRANSLATIONS.UPLOAD_NEW_TRUCK_FILE]: 'Upload new truck matrix file',
      [TRANSLATIONS.DELIVERER_TRUCK_REQUIREMENTS_PDF]: 'Deliverer-Truck Requirements (PDF)',
      [TRANSLATIONS.RISK_PATH_FLOW_PDF]: 'Risk Path Flow (PDF)',
      [TRANSLATIONS.INFORMATION_RESOURCES]: 'Informative resources',
      [TRANSLATIONS.LOADING_ERROR_PLEASE_RETRY]: 'Error while uploading, please retry',
      [TRANSLATIONS.UPLOAD_ANOTHER_FILE]: 'Upload a new file',
      [TRANSLATIONS.UPLOAD_NEW_DRIVER_FILE]: 'Upload new driver file',
      [TRANSLATIONS.NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_DRIVER_FILE]: 'No data loaded yet. Press ”Upload new driver file”.',
      [TRANSLATIONS.SELECT_A_DISTRIBUTION_CENTER]: 'Select a Distribution Center',
      [TRANSLATIONS.SELECT_A_BUSINESS_UNIT]: 'Select a BU',
      [TRANSLATIONS.IN_ORDER_TO_ACCESS_THE_ROAD_SAFETY_SETTINGS_IT_IS_NECESSARY_TO_SELECT_A_LOCATION_USING_THE_TOP_BAR]: 'To access this page you must select a Distribution Center in the search bar at the top of the page.',
      [TRANSLATIONS.IN_ORDER_TO_ACCESS_DAILY_AGENDA_IT_IS_NECESSARY_TO_SELECT_A_BUSINESS_UNIT_USING_THE_TOP_BAR]: 'To access this page you must select a BU in the search bar at the top of the page.',
      [TRANSLATIONS.UPLOADING_FILE]: 'Uploading file',
      [TRANSLATIONS.THE_FOLLOWING_USERS_HAD_A_PROBLEM_LOADING_IT_MAY_BE_THAT_THEY_DO_NOT_EXIST_IN_THE_SYSTEM_BASE_OR_THAT_THEIR_QR_IS_INCORRECT]: 'The following users had a problem loading. It may be that they do not exist in the system base or that their QR is incorrect.',
      [TRANSLATIONS.PROBLEM_WITH_USERS]: 'Problem with user(s)',
      [TRANSLATIONS.SOME_OF_THE_USERS_DO_NOT_EXIST_IN_THE_SAFETY_DATABASE_REQUEST_THEIR_REGISTRATION_BEFORE_UPLOADING_THEM_HERE]: 'Some of the users do not exist in the Safety database. Request their registration before uploading them here.',
      [TRANSLATIONS.LAST_UPDATED_ON_RS]: 'Last updated on',
      [TRANSLATIONS.YOU_ARE_ABOUT_TO_CLOSE_AN_INSECURE_ACT_THAT_IS_A_GOLDEN_RULE]: 'You are about to close an insecure act that is a golden rule',
      [TRANSLATIONS.HAS_THE_CORRESPONDING_CONSEQUENCE_FLOW_BEEN_APPLIED]: 'Has the corresponding consequence flow been applied?',
      [TRANSLATIONS.UPLOAD_NEW_ROUTE_FILE]: 'Upload new file of routes',
      [TRANSLATIONS.UPLOAD_ROAD_SAFETY_CHECKLIST_STRUCTURE]: 'Update Road Safety checklist structure',
      [TRANSLATIONS.NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_CRITICAL_PATH_FILE]: 'There is no data. Click “Update the list of critical routes”',
      [TRANSLATIONS.RETURN_TO_CRITICAL_PATHS]: 'Return to Critical Routes',
      [TRANSLATIONS.ROUTE]: 'Route',
      [TRANSLATIONS.ROUTE_DATA]: 'Route data',
      [TRANSLATIONS.GENERAL_INFORMATION]: 'General information',
      [TRANSLATIONS.ROUTE_ID]: 'Route ID',
      [TRANSLATIONS.LAST_UPDATE]: 'Last update',
      [TRANSLATIONS.LAST_DRIVER]: 'Last driver',
      [TRANSLATIONS.DESTINATION]: 'Destination',
      [TRANSLATIONS.SEE_ROUTE]: 'See route',
      [TRANSLATIONS.DRIVER_SUCCESSFULLY_REMOVED]: 'Driver successfully removed',
      [TRANSLATIONS.WITHOUT_CODE]: 'Without code',
      [TRANSLATIONS.LAST_TRUCK]: 'Last Truck',
      [TRANSLATIONS.DRIVER]: 'Driver',
      [TRANSLATIONS.TRUCK]: 'Truck',
      [TRANSLATIONS.EVALUATOR]: 'Evaluator',
      [TRANSLATIONS.FIND_ANOTHER_ROUTE]: 'Find another route',
      [TRANSLATIONS.REMOVE]: 'Remove',
      [TRANSLATIONS.FROM_THE_ROUTE]: 'from the route',
      [TRANSLATIONS.DRIVERS_SUITABLE_FOR_THE_SELECTED_ROUTE]: 'Drivers suitable for the selected route',
      [TRANSLATIONS.APPLY_CHANGE]: 'Apply change',
      [TRANSLATIONS.TRUCKS_SUITABLE_FOR_SELECTED_ROUTES]: 'Trucks suitable for the selected route',
      [TRANSLATIONS.SEARCH_TRUCK]: 'Search truck',
      [TRANSLATIONS.PLANNING_FOR_TODAY]: 'Planning for today',
      [TRANSLATIONS.SELECT_RS]: 'Select',
      [TRANSLATIONS.SELECT_DRIVER]: 'Select driver',
      [TRANSLATIONS.ADD_DRIVER]: 'Add driver',
      [TRANSLATIONS.AT_RISK]: 'At risk',
      [TRANSLATIONS.CRITICAL]: 'Critical',
      [TRANSLATIONS.RELIEF_DRIVER]: 'Chofer Relevo',
      [TRANSLATIONS.TIME_FOR_CHANGE]: 'Time for change',
      [TRANSLATIONS.DRIVER_WITHOUR_ROUTE]: 'Driver without route',
      [TRANSLATIONS.CHANGE_DRIVER]: 'Change driver',
      [TRANSLATIONS.CHANGE_TRUCK]: 'Change truck',
      [TRANSLATIONS.THERE_ARE_NO_OPTIONS_AVAILABLE_CANNOT_GO_ON_ROUTE]: 'There are no options available. CANNOT go on route',
      [TRANSLATIONS.DUPLICATE_ON_ROTUE]: 'Duplicate on route',
      [TRANSLATIONS.LACK_OF_DRIVER_CANNOT_GO_ON_ROUTE]: 'Lack of driver. CANNOT go on route',
      [TRANSLATIONS.SEARCH_DRIVER]: 'Search driver',
      [TRANSLATIONS.ROUTE_SUCCESSFULLY_UPDATED]: 'Route successfully updated',
      [TRANSLATIONS.PLEASE_SELECT_RELY_TIME_FOR_THIS_TRIP]: 'Please, select relay time for this trip',
      [TRANSLATIONS.DRIVER_MATRIX]: 'Driver matrix',
      [TRANSLATIONS.FINANZAS]: 'Finances',
      [TRANSLATIONS.COMERCIAL]: 'Commercial',
      [TRANSLATIONS.SERVICIOS]: 'Services',
      [TRANSLATIONS.CREATE_USER]: 'Create user',
      [TRANSLATIONS.LOCATION_SELECT]: 'Select location',
      [TRANSLATIONS.CREATE_BY_PIN]: 'Create by PIN',
      [TRANSLATIONS.CREATE_BY_SHARP]: 'Create by SHARP',
      [TRANSLATIONS.USER_INFORMATION]: 'User information',
      [TRANSLATIONS.FULL_NAME]: 'Full name',
      [TRANSLATIONS.ENTER_USERS_FULL_NAME]: "Enter the user's full name",
      [TRANSLATIONS.SAP]: 'SAP/SHARP',
      [TRANSLATIONS.ENTER_USERS_SAP]: "Enter the user's SAP/SHARP",
      [TRANSLATIONS.SELECT_USERS_POSITION]: "Select the user's position",
      [TRANSLATIONS.SELECT_HIRING_DATE]: 'Select the hiring date',
      [TRANSLATIONS.USER_PERMISSIONS]: 'User permissions',
      [TRANSLATIONS.SAP_MUST_CONTAIN_8_DIGITS]: 'The SAP/SHARP must contain 8 digits.',
      [TRANSLATIONS.SAP_SHARP_AVAILABLE]: 'SAP/SHARP available.',
      [TRANSLATIONS.SAP_SHARP_ALREADY_REGISTERED]: 'SAP/SHARP already registered.',
      [TRANSLATIONS.USER_CREATED_SUCCESSFULLY]: 'User created successfully',
      [TRANSLATIONS.SAP_SHOULD_ONLY_CONTAIN_NUMBERS]: 'The SAP/Sharp should only have numbers.',
      [TRANSLATIONS.PLEASE_ENTER_8_CHARACTERS]: 'Please enter at least 8 characters',
      [TRANSLATIONS.SPECIAL_CHARACTERS_NOT_ALLOWED]: 'Special characters not allowed',
      [TRANSLATIONS.WRITE_HERE_THE_DESCRIPTION_OF_THE_CONSEQUENCE_APPLIED]: 'Write here the description of the consequence applied',
      [TRANSLATIONS.PATIO_DRIVER]: 'Patio Driver',
      [TRANSLATIONS.EDIT_USER_WITHOUT_ROLE]: 'User does not have a role, editing this user without selecting a role will give certificates only',
      [TRANSLATIONS.ROLE_AND_LOCATION_TYPE_MISMATCH]: 'Assigned Role does not match the type of operation take this in mind before saving',
      [TRANSLATIONS.AUTOMATIC_ASSIGNMENT_OF_ACIS]: 'Automatic assignment of ACIs',
      [TRANSLATIONS.ALLOW_AUTOMATIC_ASSIGNMENT_OF_ACIS]: "Allow automatic assignment of SIO's",
      [TRANSLATIONS.PLEASE_SELECT_AREA]: 'Please select area',
      [TRANSLATIONS.PLEASE_SELECT_SUBAREA]: 'Please select subarea',
      [TRANSLATIONS.SELECT_LOCATIONS_FOR_AUTO_ASSIGN_SIOS_IN_PERMISSION_TAB]: "Select locations for auto assing SIO's in permission tab.",
      [TRANSLATIONS.SEARCH_FOR_OWD_HERE]: 'Search for OWD here',
      [TRANSLATIONS.BLOCKED_USER_SHOULD_CONTACT_A_SUPERUSER_FOR_MORE_INFORMATION]: 'Blocked user should contact a superuser for more information.',
      [TRANSLATIONS.CHECKLIST_DELETED_SUCCESSFULLY]: 'Checklist deleted successfully',
      [TRANSLATIONS.CHECKLIST_EDITED_CORRECTLY]: 'Checklist edited correctly',
      [TRANSLATIONS.ERROR_PLEASE_RETRY]: 'Error, please retry',
      [TRANSLATIONS.NO_DATA_FOUND_FOR_THIS_SECTION_SO_FAR]: 'No data found for this section so far',
      [TRANSLATIONS.ENTER_YOUR_NAME]: 'Enter your name',
      [TRANSLATIONS.ENTER_YOUR_FIRST_SURNAME]: 'Enter your First surname',
      [TRANSLATIONS.ENTER_YOUR_SECOND_SURNAME]: 'Enter your Second surname',
      [TRANSLATIONS.ENTER_YOUR_EMAIL]: 'Enter your email',
      [TRANSLATIONS.DATE_MUST_BE_WITHIN_THE_PAST_100_YEARS]: 'Date must be within the past 100 years.',
      [TRANSLATIONS.USE_SSO_TO_LOGIN]: 'Use SSO to login',
      [TRANSLATIONS.DAILY_AGENDA]: 'Daily agenda',
      [TRANSLATIONS.SCHEDULE]: 'Schedule',
      [TRANSLATIONS.ACTIVITIES]: 'Activities',
      [TRANSLATIONS.TOTAL_MONTH]: 'Total month',
      [TRANSLATIONS.EDIT_SCHEDULE]: 'Edit schedule',
      [TRANSLATIONS.DRAG_A_CREATED_TASK_TO_START]: 'Drag a created task to start',
      [TRANSLATIONS.DELETE_POSITION]: 'Delete position',
      [TRANSLATIONS.THERE_ARE_NO_POSITIONS_CREATED_YET_TO_ADD_A_NEW_ONE_PRESS_ADD_POSITION]: 'There are no positions created yet, to add a new one press “+ Add position”.',
      [TRANSLATIONS.THRE_ARE_NO_TASKS_CREATED_YET_TO_CREATE_A_NEW_ONE_PRESS_NEW]: 'There are no tasks created yet, to create a new one press “+ New”.',
      [TRANSLATIONS.THE_TASKS_OF_THE_DPO_MODULES_ARE_ALREADY_CONNECTD_TO_THE_DAILY_AGENDA_THEY_DO_NOT_NEED_TO_BE_CREATED]: 'The tasks of the DPO modules are already connected to the Digital Agenda, they do not need to be created.',
      [TRANSLATIONS.NEW]: 'New',
      [TRANSLATIONS.ADD_POSITION]: 'Add position',
      [TRANSLATIONS.POSITIONS_OF]: 'Positions of',
      [TRANSLATIONS.ACCUMULATIVE]: 'Accumulative',
      [TRANSLATIONS.DAILY]: 'Daily',
      [TRANSLATIONS.WEEKLY]: 'Weekly',
      [TRANSLATIONS.BIWEEKLY]: 'BiWeekly',
      [TRANSLATIONS.MONTHLY]: 'Monthly',
      [TRANSLATIONS.QUARTERLY]: 'Quarterly',
      [TRANSLATIONS.NEW_ACTIVITY]: 'New activity',
      [TRANSLATIONS.WRITE_ACTIVITY_NAME]: 'Write activity name',
      [TRANSLATIONS.WRITE_ENGLISH_ACTIVITY_NAME]: 'Write activity English name',
      [TRANSLATIONS.WRITE_DESCRIPTION]: 'Write description',
      [TRANSLATIONS.THIS_ACTIVITY_WILL_ONLY_DISPLAY_ONE_QUESTION_IN_THE_MOBILE_APP_WHICH_WILL_BE_TO_REQUEST_PHOTOGRAPHIC_EVIDENCE]: 'This activity will only display one question in the mobile app, which will be to request photographic evidence.',
      [TRANSLATIONS.SELECT_PILLAR]: 'Select pillar',
      [TRANSLATIONS.CREATE]: 'Create',
      [TRANSLATIONS.TO_EDIT_ANOTHER_ONE_GO_BACK_AND_SELECT_A_DIFFERENT_BU]: 'To edit another one, go back and select a different BU.',
      [TRANSLATIONS.PROGRAMMING_OF]: 'Programming of',
      [TRANSLATIONS.EDITING_AGENDA_PROGRAMMING]: 'Editing agenda programming',
      [TRANSLATIONS.SAVE_CHANGES]: 'Save changes',
      [TRANSLATIONS.PROGRAMMING_AGENDA]: 'Programming agenda',
      [TRANSLATIONS.WATCHING_PROGRAMMING_OF]: 'Watching programming of',
      [TRANSLATIONS.THERE_IS_NO_SCHEDULE_YET_TO_START_PRESS_EDIT_SCHEDULE]: 'There is no schedule yet, to start press “Edit schedule”.',
      [TRANSLATIONS.THERE_ARE_N0_SCHEDULES_RECORDED_FOR_THE_SELECTED_BU_YET_START_BY_PRESSING_THE_EDIT_SCHEDULE_BUTTON_AT_THE_TOP_RIGHT_TO_BEGIN_ADDING_TASKS_TO_THE_DIFFERENT_POSITIONS]: 'There are no schedules recorded for the selected BU yet, start by pressing the Edit Schedule button at the top right to begin adding tasks to the different positions.',
      [TRANSLATIONS.PROGRAMMING_EFFECTIVE_FROM]: 'Programming effective from',
      [TRANSLATIONS.THESE_CHANGES_WILL_TAKE_EFFECT_UNTIL]: 'These changes will take effect until',
      [TRANSLATIONS.SELECT_THE_BU_AND_PERIOD_TO_DOWNLOAD_THE_DATA]: 'Select the bu and period to download the data',
      [TRANSLATIONS.DOWNLOADING_PROGRAMMING_OF]: 'Downloading programming of',
      [TRANSLATIONS.THE_QUARTERLY_ACTIVITIES_DO_NOT_COUNT_FOR_THE_MONTHLY_SUMS_OF_THE_USERS]: 'The quarterly activities do not count for the monthly sums of the users.',
      [TRANSLATIONS.THESE_ACTIVITIES_HAVE_INDIVIDUAL_DUE_DATES]: 'These activities have individual due dates.',
      [TRANSLATIONS.YOU_HAVE_UNTIL]: 'You have until',
      [TRANSLATIONS.QUARTERLY_PROGRESS]: 'quarterly progress',
      [TRANSLATIONS.MONTHLY_PROGRESS]: 'monthly progress',
    },
  },
};

function getParameterByName(name: any) {
  const url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

i18next.use(initReactI18next).init({
  resources,
  lng: getParameterByName('lng') as string || navigator.language,
  // lng: navigator.language,
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
