import React, { FC, useMemo } from 'react';
import { useExpanded, useTable } from 'react-table';
import ClosedIcon from '../../assets/icons/chevron-closed-down.svg';
import OpenIcon from '../../assets/icons/chevron-closed-right.svg';
import './ExpandableTable.scss';

type Props = {
  columns: any;
  data: any;
  isExpandable?: boolean;
  showExpandedRowSubHeader?: boolean;
  tableClassName?: string;
  removeDepthMargin?: boolean;
  showExpanderAtLast?: boolean;
  openIcon?: any;
  closeIcon?: any;
};

const ExpandableTable: FC<Props> = ({
  columns,
  data,
  isExpandable,
  showExpandedRowSubHeader = false,
  tableClassName,
  removeDepthMargin,
  showExpanderAtLast,
  openIcon,
  closeIcon,
}: Props) => {
  // const expandRowAction = (row: any, rows: any, toggleRowExpanded: any) => {
  //   const expandedRow = rows.find((currentRow: any) => currentRow.isExpanded);

  //   if (expandedRow) {
  //     const isSubItemOfRow = Boolean(expandedRow && row.id.split('.')[0] === expandedRow.id);

  //     if (isSubItemOfRow) {
  //       const expandedSubItem = expandedRow.subRows.find((subRow: any) => subRow.isExpanded);

  //       if (expandedSubItem) {
  //         const isSubSubItemOfRow = Boolean(
  //           row.id.split('.')[1] === expandedSubItem.id.split('.')[1],
  //         );
  //         const expandedSubSubItem = expandedSubItem.subRows.find(
  //           (subRow: any) => subRow.isExpanded,
  //         );
  //         if (isSubSubItemOfRow) {
  //           if (expandedSubSubItem) {
  //             const isClickedOnExpandedSubSubItem = expandedSubSubItem.id === row.id;
  //             if (!isClickedOnExpandedSubSubItem) {
  //               toggleRowExpanded(expandedSubSubItem.id, false);
  //             }
  //           }
  //         } else {
  //           const isClickedOnExpandedSubItem = expandedSubItem.id === row.id;
  //           if (!isClickedOnExpandedSubItem) {
  //             toggleRowExpanded(expandedSubItem.id, false);
  //           }
  //         }
  //       }
  //     } else {
  //       toggleRowExpanded(expandedRow.id, false);
  //     }
  //   }
  //   row.toggleRowExpanded();
  // };

  const expandRowAction = (row: any, rows: any, toggleRowExpanded: any) => {
    const collapseExpandedRows = (currentRows: any[], targetRow: any) => {
      currentRows.forEach((currentRow: any) => {
        if (currentRow.isExpanded) {
          const isSubItemOfRow = targetRow.id.startsWith(currentRow.id);
          if (!isSubItemOfRow) {
            toggleRowExpanded(currentRow.id, false);
          }
          if (currentRow.subRows && currentRow.subRows.length > 0) {
            collapseExpandedRows(currentRow.subRows, targetRow);
          }
        }
      });
    };
    collapseExpandedRows(rows, row);
    row.toggleRowExpanded();
  };


  const expandableColumns = useMemo(
    () => {
      const expanderColumn =  {
        id: 'expander',
        Cell: ({ row }: { row: any }) => (row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: { marginLeft: removeDepthMargin ? 0 : `${row.depth * 10}px` },
            })}
          >
            <span
              role="button"
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              onClick={() => expandRowAction(row, rows, toggleRowExpanded)}
            >
              {row.isExpanded ? (
                <img
                  src={openIcon ?? OpenIcon}
                  alt="back"
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              ) : (
                <img
                  src={closeIcon ?? ClosedIcon}
                  alt="back"
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              )}
            </span>
          </span>
        ) : null),
      };

      return showExpanderAtLast
        ? [...columns, expanderColumn]
        : [expanderColumn, ...columns];
    },
    // eslint-disable-next-line
    [columns],
  );

  const conditionalProps = (row: any, rows: any, toggleRowExpanded: any) => {
    if (row.canExpand) {
      return {
        ...row.getToggleRowExpandedProps({
          style: {},
          onClick: () => expandRowAction(row, rows, toggleRowExpanded),
        }),
      };
    }
    return {};
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleRowExpanded,
  } = useTable({ columns: isExpandable ? expandableColumns : columns, data }, useExpanded);
  return (
    <div className="expandable-table">
      <table {...getTableProps()} className={tableClassName ?? 'table'}>
        <thead className="some">
          {headerGroups.map(
            (headerGroup: {
              getHeaderGroupProps: () => JSX.IntrinsicAttributes &
              React.ClassAttributes<HTMLTableRowElement> &
              React.HTMLAttributes<HTMLTableRowElement>;
              headers: any[];
            }) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, i: number) => (
                  <th {...column.getHeaderProps()} style={{ height: 45 }} key={i}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ),
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row: {
              getRowProps: () => JSX.IntrinsicAttributes &
              React.ClassAttributes<HTMLTableRowElement> &
              React.HTMLAttributes<HTMLTableRowElement>;
              cells: any[];
              isExpanded?: boolean;
              canExpand?: boolean;
              depth?: any;
              id: any;
            }) => {
              prepareRow(row as any);
              const rowID = row.id ?? 0;
              if ((row as any).values.frequency === 'QUARTERLY') {
                console.log('%c⧭', 'color: #00b300', row);
              }
              const isHighlighted = (row as any).values.frequency === 'QUARTERLY';
              if (row.depth === 1 && !!showExpandedRowSubHeader) {
                if (String(rowID).split('.')[1] === '0') {
                  return (
                    <>
                      <tr
                        className="row-subHeader"
                        {...row.getRowProps()}
                        {...conditionalProps(row, rows, toggleRowExpanded)}
                      >
                        {row.cells.map((cell: any, i: number) => {
                          return (
                            <td
                              className="row-subHeader-title"
                              key={i}
                              {...cell.getCellProps()}
                              {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                              style={{
                                height: 40,
                              }}
                            >
                              {(() => {
                                if (i === 0) return '';
                                if (row.cells[i].column.Header === '') return 'RUTINAS';
                                if (row.cells[i].column.Header === 'DC') return 'PERIODICIDAD';
                                if (row.cells[i].column.Header === 'Prog. actual') return 'PROGRESO';
                                if (row.cells[i].column.Header === 'Última') return 'ÚLTIMA';
                                return '';
                              })()}
                            </td>
                          );
                        })}
                      </tr>
                      <tr
                        {...row.getRowProps()}
                        {...conditionalProps(row, rows, toggleRowExpanded)}
                      >
                        {row.cells.map((cell: any, i: number) => {
                          return (
                            <td
                              key={i}
                              {...cell.getCellProps()}
                              {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                              style={{
                                height: 45,
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                }
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`
                  row-subItems
                `}
                    {...conditionalProps(row, rows, toggleRowExpanded)}
                  >
                    {row.cells.map((cell: any, i: number) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                          style={{
                            height: 45,
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
              return (
                <tr
                  {...row.getRowProps()}
                  className={`
                row
                row_depth${row.depth} 
                ${row.isExpanded ? 'expanded-row' : ''}
                ${row.canExpand ? 'expandable-row' : ''}
                ${
                  row.cells.some(
                    (cell: any) =>
                      cell.column.Header === 'Frequency' && cell.value,
                  )
                    ? 'highlighted-row'
                    : ''
                }
                `}style={
                  isHighlighted
                    ? { backgroundColor: '#FAF7ED', color: '#CAA038' }
                    : {}
                }
                {...conditionalProps(row, rows, toggleRowExpanded)}
                >
                  {row.cells.map((cell: any, i: number) => {
                    let paddingLeft = 12;
                    if (row.depth === 1) paddingLeft = 20;
                    if (row.depth === 2) paddingLeft = 30;
                    if (row.depth === 3) paddingLeft = 40;
                    if (row.depth === 4) paddingLeft = 50;
                    if (row.depth === 5) paddingLeft = 50;
                    if (row.depth === 6) paddingLeft = 50;
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                        className={`
                        cell_depth${row.depth}
                        ${isExpandable ? '' : 'cell'}
                        ${row.isExpanded ? 'expanded-row-items' : ''}
                        ${tableClassName ? ((i === 0 && row.isExpanded) ? 'expanded-row-title' : 'row-title') : ''}
                      `}
                        style={{
                          height: row.depth === 0 ? 45 : 35,
                          ...(tableClassName && { paddingLeft: (i === 0 ? paddingLeft : 12) }),
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            },
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandableTable;
